import React from "react";

interface DelayedComponentProps {
  delay?: number;
}

interface DelayedComponentState {
  ready: boolean;
}

/**
 * This is currently used to fix a popover-related bug. See
 * https://github.com/arnthor3/react-delay-render/blob/master/src/index.jsx for inspiration.
 */
const withDelayedRender = <P extends React.Component>(WrappedComponent: React.ComponentType<P>) => {
  return class extends React.Component<P & DelayedComponentProps, DelayedComponentState> {
    private _timeout;

    constructor(props) {
      super(props);
      this.state = { ready: !this.props.delay };
    }

    render() {
      if (this.state.ready) {
        return <WrappedComponent {...this.props} />;
      }
      return "";
    }

    componentDidMount(): void {
      if (!this.props.delay) {
        return;
      }
      this._timeout = setTimeout(() => {
        this.setState({ ready: true });
      }, this.props.delay);
    }

    componentWillUnmount(): void {
      clearTimeout(this._timeout);
    }
  };
};

export default withDelayedRender;
