import React, { useLayoutEffect, useRef, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import styled, { ThemeProvider } from "styled-components";

import { format } from "../shared/util/data-formatters";

import { ChartProps, ColorRow, Column, Data, Row } from "./models/chart-props";
import { NivoData } from "./nivo/NivoData";
import { renderTick, renderWords, splitLabel, tickWordElement } from "./nivo/NivoHelpers";
import { ChartTheme, Theme, ThemeDefinitions } from "./Theme";

// Some notes on the implementation here:
// * We use tspan elements to break apart multi-word labels. See https://github.com/plouc/nivo/issues/353 for context
//   on why we needed to use this approach.
// * For design reasons, we only need to break apart multi-word labels if they're particularly long. We deduce whether
//   a label is particularly long in the component component because otherwise we'd have to plumb a parameter in from
//   a presenter.
//
// Limitations and assumptions:
// * It would be reasonable to, at some point, need an "..." to cut off the overflow of certain labels. We
//   haven't hit that use case yet so the BarChart component does not support it.

// These values will be set when rendering the bar chart.
let maxLabelWidth;
let theme;
let longestLabelLength;
let hasTickRotation;

const themeDefinitions = new ThemeDefinitions<ChartTheme>({
  web: {
    chartContainer: {
      height: "360px",
    },
    labelLineHeight: 15,
    verticalOffset: 15,
    leftMargin: 55,
    rightMargin: 0,
  },
  pdf: {
    chartContainer: {
      height: "175px",
    },
    labelLineHeight: 10,
    verticalOffset: 10,
    leftMargin: 40,
    rightMargin: 35,
  },
});

const isLongLabel = (label: string): boolean => {
  return label.length > maxLabelWidth;
};

const calculateMargin = (): number => {
  // Determine how many rows the longest tick label could take and then multiply by the line height. Also add in a
  // fixed buffer because charts are cut off a little bit by default.
  const buffer = hasTickRotation ? 40 : 20;
  const labelLineHeight = theme.labelLineHeight;
  return Math.ceil(longestLabelLength / maxLabelWidth) * labelLineHeight + buffer;
};

const tickLabelElement = (label: string): JSX.Element | JSX.Element[] => {
  const initialOffset = 5;
  const verticalOffset = theme.verticalOffset;

  if (!isLongLabel(label)) {
    return tickWordElement(label, initialOffset);
  }
  const xAxisLabelWidth = hasTickRotation ? longestLabelLength : maxLabelWidth;
  const segments = splitLabel(label, xAxisLabelWidth);

  return renderWords(segments, initialOffset, verticalOffset);
};

const ChartContainer = styled.div`
  height: ${(props) => props.theme.chartContainer.height};
`;

interface BarChartColumn extends Column {
  color: string;
}

type BarChartProps = ChartProps<BarChartColumn, ColorRow | Row, Data, Theme>;

const getPortfolioOrBenchmarkColor = (portfolioName: string): Function => {
  return (row) => {
    // return portfolio color for portfolio bar, or model benchmark color for model benchmark bar
    return row.id === portfolioName ? row.data.portfolio_color : row.data.model_portfolio_benchmark_color;
  };
};

// If colors come in on the rows they will supersede any colors set on the columns.
const getColors = (props: BarChartProps, nivoData: Record<string, any>): string[] | Function => {
  if ((props.rows[0] as ColorRow).portfolio_color) {
    // If chart has color-coded rows AND columns, get separate bar colors for portfolio and benchmark
    return getPortfolioOrBenchmarkColor(props.columns[0].name);
  } else if ((props.rows[0] as ColorRow).color) {
    return (row) => row.data.color;
  } else {
    // the portfolio name used to access its Historical Market Event values
    const portfolioName = props.columns[0].name;
    // add the portfolio Historical Market Event values to see if there are any data points
    const portfolioHoldingsSum = nivoData.data.reduce(function (sum, events) {
      return sum + Number(new Map(Object.entries(events)).get(portfolioName));
    }, 0);
    // return only the benchmark color if there are no data points so that the bars don't have the portfolio's color
    return portfolioHoldingsSum === 0 ? [props.columns[1].color] : props.columns.map((column) => column.color);
  }
};

const BarChart = (props: BarChartProps): JSX.Element => {
  const nivoData = NivoData(props);

  const xAxisLabels = Object.keys(nivoData.rowsByName);
  hasTickRotation = xAxisLabels.length > 12;

  const longestLabel = xAxisLabels.sort(function (a, b) {
    return b.length - a.length;
  })[0];
  longestLabelLength = longestLabel.length;

  const colors = getColors(props, nivoData);
  const names = props.columns.map((column) => column.name);
  const [width, setWidth] = useState(0); //
  const targetRef = useRef(null);
  theme = themeDefinitions.pickTheme(props.theme);

  // useLayoutEffect forces React to wait for the width calculation before screen is updated and displayed to user,
  // thus ensuring labels are rendered with correct spacing.
  useLayoutEffect(() => {
    function getUpdatedChartWidth() {
      setWidth(targetRef.current ? targetRef.current.offsetWidth : 0);
    }

    // Every time window resizes, get updated chart width to recalculate max label width.
    window.addEventListener("resize", getUpdatedChartWidth);
    // Handle the edge case where a user refreshes and lands directly on the page. Update chart width once
    // assets have been fully parsed and applied.
    window.addEventListener("load", getUpdatedChartWidth);
    getUpdatedChartWidth();
    return () => {
      window.removeEventListener("resize", getUpdatedChartWidth);
      window.removeEventListener("load", getUpdatedChartWidth);
    };
  }, [targetRef.current && targetRef.current.offsetWidth]);

  // Subtracting from width the average width of the y-axis labels. Then divide by the number of labels needed which is
  // then divided by roughly how many pixels an average character takes.
  const yAxisWidth = 75;
  const averageCharacterWidth = 5.8;

  // For quite long labels, we need to tweak the max width downward to allow for padding. For unclear reasons, this minor
  // offset causes the PDF to not display the labels at all in some cases. The additional padding is also less necessary
  // in the PDF case because it's wider.
  const widthModifier = props.theme === "pdf" ? 0 : 2;
  maxLabelWidth = Math.ceil((width - yAxisWidth) / xAxisLabels.length / averageCharacterWidth) - widthModifier;

  const formatTickValue = (value) => format(props.columns[0], value);

  const tooltipColor = (columnName) => {
    return nivoData.columnsByName[columnName].color;
  };

  const holdingsWeights = () => {
    const weights = [];
    nivoData.data.forEach(function (obj) {
      weights.push(obj[props.columns[0].name]);
      weights.push(obj[props.columns[1].name]);
    });
    return weights;
  };

  const getMaxYaxisValue = () => {
    const maxWeight = Math.max(...holdingsWeights());
    const scale = maxWeight < 100 ? 5 : 20;
    return maxWeight > 0 ? Math.ceil(maxWeight / scale) * scale : 0;
  };

  const getMinYaxisValue = () => {
    const minWeight = Math.min(...holdingsWeights());
    const scale = minWeight > -100 ? 5 : 20;
    return minWeight < 0 ? Math.floor(minWeight / scale) * scale : 0;
  };

  return (
    <ThemeProvider theme={theme}>
      <ChartContainer ref={targetRef}>
        <ResponsiveBar
          data={nivoData.data}
          keys={names}
          indexBy="name"
          maxValue={getMaxYaxisValue()}
          minValue={getMinYaxisValue()}
          margin={{
            top: 5,
            right: theme.rightMargin,
            bottom: calculateMargin(),
            left: theme.leftMargin,
          }}
          padding={0.7}
          colors={colors as any}
          groupMode="grouped"
          axisBottom={{
            tickSize: 5,
            tickPadding: 10,
            tickRotation: hasTickRotation ? -30 : 0,
            renderTick: renderTick(props.theme, tickLabelElement),
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 5,
            renderTick: renderTick(props.theme, formatTickValue),
          }}
          enableLabel={false}
          tooltip={({ id, value, indexValue }) => {
            return nivoData.tooltip(id, `${id} - ${indexValue}`, value, tooltipColor(id));
          }}
        />
      </ChartContainer>
    </ThemeProvider>
  );
};

export default BarChart;
