import React from "react";
import debounce from "lodash.debounce";

import RouteUtilities from "../../javascripts/route-utilities";

import { Portfolio } from "./models/portfolio";
import HoldingsApi, { GetInvestmentVehicles } from "./models/data-api";
import { Holding } from "./models/holding";
import PortfolioForm from "./PortfolioForm";
import PortfolioWizard from "./PortfolioWizard";
import HoldingsPaths from "./models/holdings-paths";

enum CrudMode {
  Crud = "crud",
  AnalysisWizard = "analysis_wizard",
}

enum HoldingsClass {
  Portfolio = "portfolio",
  Benchmark = "benchmark",
}

interface HoldingsProp {
  name: string;
  id: string;
  holdings: {}[];
}

interface HoldingsCrudContainerProps {
  holdings?: HoldingsProp;
  holdingsClass?: HoldingsClass;
  crudMode?: CrudMode;
  basePath?: string;
  errors?: {};
}

interface HoldingsCrudContainerState {
  portfolio: Portfolio;
}

interface PortfolioCrudProps {
  portfolio: Portfolio;
  addHolding: (holding: Holding) => void;
  updateHoldingWeight: (holding: Holding, newWeight: number) => void;
  removeHolding: (holding: Holding) => void;
  setName: (name: string, validate: boolean) => void;
  savePortfolio: () => Promise<void>;
  getInvestmentVehicles: GetInvestmentVehicles;
  holdingsClass: string;
  crudMode: CrudMode;
}

class HoldingsCrudContainer extends React.Component<HoldingsCrudContainerProps, HoldingsCrudContainerState> {
  constructor(props: HoldingsCrudContainerProps) {
    super(props);

    const portfolio = new Portfolio({ ...props.holdings, errors: props.errors || {} });
    this.state = {
      portfolio,
    };
    this.addHolding = this.addHolding.bind(this);
    this.updateHoldingWeight = this.updateHoldingWeight.bind(this);
    this.removeHolding = this.removeHolding.bind(this);
    this.setName = this.setName.bind(this);
    this.savePortfolio = this.savePortfolio.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.setHoldingsErrors = this.setHoldingsErrors.bind(this);
    this.useWizard = !(props.holdings && props.holdings.name); // render wizard if no or blank portfolio
    const holdingsClass = props.holdingsClass || HoldingsClass.Portfolio;
    this.crudMode = props.crudMode || CrudMode.AnalysisWizard;
    this.holdingsPaths = new HoldingsPaths(holdingsClass, this.crudMode, props.basePath);
    this.holdingsApi = new HoldingsApi(this.holdingsPaths);
  }

  private useWizard: boolean;
  private holdingsPaths: HoldingsPaths;
  private holdingsApi: HoldingsApi;
  private crudMode: CrudMode;

  public render() {
    return this.useWizard ? (
      <PortfolioWizard
        portfolio={this.portfolio}
        addHolding={this.addHolding}
        updateHoldingWeight={this.updateHoldingWeight}
        removeHolding={this.removeHolding}
        setName={this.setName}
        savePortfolio={this.savePortfolio}
        getInvestmentVehicles={this.holdingsApi.getInvestmentVehicles}
        holdingsClass={this.holdingsPaths.holdingsClass}
        crudMode={this.crudMode}
      />
    ) : (
      <PortfolioForm
        portfolio={this.portfolio}
        addHolding={this.addHolding}
        updateHoldingWeight={this.updateHoldingWeight}
        removeHolding={this.removeHolding}
        setName={this.setName}
        savePortfolio={this.savePortfolio}
        getInvestmentVehicles={this.holdingsApi.getInvestmentVehicles}
        holdingsClass={this.holdingsPaths.holdingsClass}
        crudMode={this.crudMode}
      />
    );
  }

  private get portfolio(): Portfolio {
    return this.state.portfolio;
  }

  private addHolding(holding) {
    this.setState((state) => {
      const portfolio = state.portfolio.cloneDeep();
      portfolio.holdings = [...portfolio.holdings, holding];
      return { portfolio };
    });
  }

  private updateHoldingWeight(holding, newWeight) {
    this.setState((state) => {
      const holdingIndex = state.portfolio.holdings.indexOf(holding);
      const portfolio = state.portfolio.cloneDeep();
      portfolio.holdings[holdingIndex].weight = newWeight;
      // need to set holdings to recalculate total weight and re-validate portfolio
      portfolio.holdings = [...portfolio.holdings];
      return { portfolio };
    });
  }

  private removeHolding(holding: Holding) {
    this.setState((state) => {
      const holdingIndex = state.portfolio.holdings.indexOf(holding);
      const portfolio = state.portfolio.cloneDeep();
      portfolio.holdings.splice(holdingIndex, 1);
      // need to set holdings to recalculate total weight and re-validate portfolio
      portfolio.holdings = [...portfolio.holdings];
      return { portfolio };
    });
  }

  private savePortfolio() {
    if (this.portfolio.id) {
      return this.update();
    } else {
      return this.create();
    }
  }

  private create() {
    return this.holdingsApi.create(this.portfolio.holdings, this.portfolio.name).then((response) => {
      if (response && response.errors) {
        this.setHoldingsErrors(response.errors.holdings);
      } else {
        RouteUtilities.setLocation(this.holdingsPaths.successRedirectUrl(response.id));
      }
    });
  }

  private update() {
    return this.holdingsApi.update(this.portfolio).then((response) => {
      if (response && response.errors) {
        this.setHoldingsErrors(response.errors.holdings);
      } else {
        RouteUtilities.setLocation(this.holdingsPaths.successRedirectUrl(response.id));
      }
    });
  }

  private setName(name: string) {
    this.setState((state) => {
      const portfolio = state.portfolio.cloneDeep();

      portfolio.name = name;
      return { portfolio };
    });
  }

  private setHoldingsErrors(errors) {
    this.setState((state) => {
      const portfolio = state.portfolio.cloneDeep();
      portfolio.errors.holdings = errors;
      return { portfolio };
    });
  }
}

export default HoldingsCrudContainer;
export { CrudMode, HoldingsClass, PortfolioCrudProps };
