import React from "react";
import moment from "moment";

import { Column } from "../../analysis/models/chart-props";
import deleteIcon from "../../../images/icon_delete.svg";

const DASH = "-";

enum Format {
  Percentage = "percentage",
  Decimal = "decimal",
  Years = "years",
  Integer = "integer",
  UsdInBillions = "usdInBillions",
  Date = "date",
  Text = "text",
  TextLink = "text_link",
  DeleteLink = "delete_link",
  Color = "color",
  // child data of the current element. Typically requires special handling and won't be formatted via this library
  Children = "children",
}

interface DateData {
  format: string;
  date: string;
}

interface TextLinkData {
  href: string;
  text: string;
  target?: string;
}

interface DeleteLinkData {
  id: string;
}

type FormatValue = string | number | DateData | TextLinkData | DeleteLinkData;

type FormatFunction = (value: FormatValue) => string | JSX.Element;

// We truncate in the Ruby layer but we also truncate here just to be sure
// Javascript doesn't have a truncate function and toFixed has all the
// usual problems of rounding and floating point representations.
//
// To truncate we round to one more decimal place than we need and chomp the resulting string
const truncate = (value: string | number, n = 2): string => {
  const localeStringOpts = { minimumFractionDigits: n + 1, maximumFractionDigits: n + 1 };
  const truncatedOneMore = value.toLocaleString(undefined, localeStringOpts);
  // chomp the last digit we didn't need and the decimal point for the n === 0 integer case
  return truncatedOneMore.replace(/\.?\d$/, "");
};

const decimal = (value: string | number) => {
  // Ruby BigDecimals get serialized as JSON strings so parse them back to Floats
  if (typeof value === "string") {
    value = parseFloat(value);
  }
  return value !== null && !isNaN(value) ? truncate(value) : DASH;
};

const percentage = (value: string | number) => {
  const formattedDecimal = decimal(value);
  return formattedDecimal !== DASH ? `${formattedDecimal}%` : DASH;
};

const years = (value: number): string => (!Number.isNaN(value) && value > 0 ? `${truncate(value)} years` : DASH);

const integer = (value: number): string => (!Number.isNaN(value) && value ? truncate(value, 0) : DASH);

const usdInBillions = (value: string | number) => {
  const formattedDecimal = decimal(value);
  return formattedDecimal !== DASH ? `$${formattedDecimal} B` : DASH;
};

const date = (value: DateData | string): string => {
  if (!value) return DASH;
  const dateData: DateData = typeof value === "string" ? { date: value, format: "M/D/YY" } : (value as DateData);

  const date = moment(dateData.date, moment.ISO_8601);
  return date.isValid() ? date.format(dateData.format) : DASH;
};

const textLink = (value: TextLinkData): JSX.Element => {
  if (value === undefined) return <></>;
  return (
    <a href={value.href} target={value.target || "_self"}>
      {value.text}
    </a>
  );
};

const deleteLink = (value: DeleteLinkData): JSX.Element => {
  if (value === undefined) return <></>;
  return (
    <a id={value.id}>
      <img src={deleteIcon} style={{ marginLeft: "15px" }} />
    </a>
  );
};

const text = (value): string => (value ? String(value) : "");

const getFormatter = (format: Format): FormatFunction => {
  switch (format) {
    case Format.Percentage:
      return percentage;
    case Format.Decimal:
      return decimal;
    case Format.Years:
      return years;
    case Format.Integer:
      return integer;
    case Format.UsdInBillions:
      return usdInBillions;
    case Format.Date:
      return date;
    case Format.TextLink:
      return textLink;
    case Format.DeleteLink:
      return deleteLink;
    default:
      return text;
  }
};

const format = (header: Column, value: FormatValue): string | JSX.Element => getFormatter(header.format)(value);

export {
  FormatFunction,
  FormatValue,
  Format,
  DateData,
  TextLinkData,
  decimal,
  percentage,
  years,
  integer,
  usdInBillions,
  date,
  textLink,
  text,
  format,
};
