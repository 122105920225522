import React from "react";
import styled from "styled-components";

import { percentage } from "../shared/util/data-formatters";
import alertIcon from "../../images/icon_alert.svg";

const FooterCell = styled.div`
   {
    font-size: 16px;
    font-weight: bold;
    vertical-align: middle;
    line-height: normal;
    border-bottom: solid 1px #ccc;
    padding: 10px ${(props) => props.rightPadding} 10px 10px;

    &:last-child {
      text-align: right;
    }
  }
`;

const GridContainer = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: repeat(2, 1fr);

  ${FooterCell}:nth-child(1) {
    -ms-grid-column: 1;
  }

  ${FooterCell}:nth-child(2) {
    -ms-grid-column: 2;
  }
`;

const spanCSS = {
  verticalAlign: "middle",
  marginLeft: "5px",
  color: "red",
  fontWeight: 400,
};

FooterCell.defaultProps = {
  rightPadding: "10px",
};

interface HoldingsFooterProps {
  currentTotalWeight: number;
  totalWeightError: string;
}

const HoldingsFooter: React.FunctionComponent<HoldingsFooterProps> = ({ currentTotalWeight, totalWeightError }) => {
  return (
    <GridContainer>
      <FooterCell>Portfolio total: </FooterCell>
      <FooterCell rightPadding={"74px"}>
        {percentage(currentTotalWeight)}
        <div hidden={!totalWeightError}>
          <img src={alertIcon} alt="alert icon" />
          <span style={spanCSS}>{totalWeightError}</span>
        </div>
      </FooterCell>
    </GridContainer>
  );
};
export default HoldingsFooter;
