interface CommonOptions {
  extraHeaders?: { [key: string]: string };
}

interface GetOptions extends CommonOptions {
  queryParams?: { [key: string]: string };
}

export default abstract class BaseClient {
  public get(path: string, { queryParams, extraHeaders }: GetOptions = {}): Promise<Response> {
    const url = `/${path}${this.buildQueryString(queryParams)}`;
    return fetch(url, {
      method: "get",
      headers: {
        Accept: "application/json",
        ...extraHeaders,
      },
    }).then((res) => (res.ok ? res : Promise.reject(res)));
  }

  public post<D extends object>(path: string, reqBody: D, { extraHeaders }: CommonOptions = {}) {
    return fetch(`/${path}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-TOKEN": this.csrfToken,
        ...extraHeaders,
      },
      body: JSON.stringify(reqBody),
    });
  }

  public put<D extends object>(path: string, reqBody: D, { extraHeaders }: CommonOptions = {}) {
    return fetch(`/${path}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-TOKEN": this.csrfToken,
        ...extraHeaders,
      },
      body: JSON.stringify(reqBody),
    });
  }

  private get csrfToken(): string {
    const csrfTokenElement = document.querySelector<HTMLMetaElement>("[name=csrf-token]");
    if (!csrfTokenElement) {
      return "";
    }
    return csrfTokenElement.content;
  }

  private buildQueryString(queryArgs: { [key: string]: string }): string {
    if (!queryArgs || Object.keys(queryArgs).length === 0) {
      return "";
    }

    const queryString = Object.keys(queryArgs)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(queryArgs[key]))
      .join("&");
    return `?${queryString}`;
  }
}
