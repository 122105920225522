import moment from "moment";

import { Column, Data } from "../../analysis/models/chart-props";

import { DateData, Format, TextLinkData } from "./data-formatters";

type SortTypeFunction = (rowA: Data, rowB: Data, columnId: string, desc: boolean) => number;

type SortTypeDataExtractFunction = (data: any) => any;

function buildSortType(dataExtractor: SortTypeDataExtractFunction) {
  // desc argument isn't needed - ReactTable takes care of inverting the order in the descending case
  return (rowA: Data, rowB: Data, columnId: string) => {
    const dataA = dataExtractor(rowA.original[columnId]);
    const dataB = dataExtractor(rowB.original[columnId]);
    return dataA > dataB ? -1 : 1;
  };
}

const dateSortType = buildSortType((data: string | DateData) => {
  let date = data;
  if (typeof data === "object") {
    date = data.date;
  }
  return moment(date as string, moment.ISO_8601);
});

const textLinkSortType = buildSortType((data: TextLinkData) => {
  return data.text.toLowerCase();
});

// See https://react-table.tanstack.com/docs/api/useSortBy
function sortType(header: Column): string | SortTypeFunction {
  let sortType;
  switch (header.format) {
    case Format.Text:
      sortType = "alphanumeric";
      break;
    case Format.TextLink:
      sortType = textLinkSortType;
      break;
    case Format.Date:
      sortType = dateSortType;
      break;
    default:
      sortType = "basic";
  }
  return sortType;
}

export { sortType, dateSortType, textLinkSortType };
