import React from "react";

import AnchoredOverlay, { CommonOverlayProps } from "./AnchoredOverlay";

const Tooltip: React.FunctionComponent<CommonOverlayProps> = (props) => {
  const triggerElement = (
    <span style={{ display: "inline-block" }} className={"tooltip__trigger"}>
      {props.triggerElement}
    </span>
  );
  return <AnchoredOverlay {...props} triggerElement={triggerElement} />;
};

export default Tooltip;
