import React from "react";
import styled from "styled-components";

import HoldingsHeaders from "./HoldingsHeaders";
import HoldingRow from "./HoldingRow";
import { Holding } from "./models/holding";
import HoldingsFooter from "./HoldingsFooter";

const Container = styled.div`
  margin-top: 50px;
`;

interface HoldingsProps {
  holdings: Holding[];
  updateHoldingWeight: (holding: Holding, newWeight: number) => void;
  removeHolding: (holding: Holding) => void;
  portfolioTotalWeight: number;
  totalWeightError: string;
}

const Holdings: React.FunctionComponent<HoldingsProps> = ({
  holdings,
  updateHoldingWeight,
  removeHolding,
  portfolioTotalWeight,
  totalWeightError,
}) => {
  if (!holdings || holdings.length === 0) return <></>;

  return (
    <Container>
      <HoldingsHeaders />
      {holdings.map((holding, index) => (
        <HoldingRow
          key={index}
          holding={holding}
          updateHoldingWeight={updateHoldingWeight}
          removeHolding={removeHolding}
        />
      ))}
      <HoldingsFooter currentTotalWeight={portfolioTotalWeight} totalWeightError={totalWeightError} />
    </Container>
  );
};

export default Holdings;
