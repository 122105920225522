import React from "react";
import styled, { css } from "styled-components";

import { cellStyles } from "./Cell";
import { DataCellProps } from "./DataCell";
import { rowSpanProps, RowSpanWrapper } from "./RowSpanWrapper";

const ColorDataCell = (props: DataCellProps): JSX.Element => {
  const { className, cell, rowSpan } = props;

  return (
    <RowSpanWrapper rowSpan={rowSpan}>
      <td className={className} {...rowSpanProps(rowSpan)} {...cell.getCellProps()} />
    </RowSpanWrapper>
  );
};

const StyledColorDataCell = styled(ColorDataCell)`
  ${cellStyles}
  width: 8px;
  ${({ cell }: DataCellProps) =>
    css`
      background-color: ${cell.value};
    `}
`;

export { StyledColorDataCell as ColorDataCell };
