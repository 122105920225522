import React from "react";
import { ResponsiveScatterPlot, Scale } from "@nivo/scatterplot";
import zipWith from "lodash.zipwith";
import styled, { ThemeProvider } from "styled-components";

import { ChartProps, ColorRow, Column } from "./models/chart-props";
import { renderTick } from "./nivo/NivoHelpers";
import { ChartTheme, Theme, ThemeDefinitions } from "./Theme";

// These values will be set when rendering the scatter plot chart.
let theme;

const themeDefinitions = new ThemeDefinitions<ChartTheme>({
  pdf: {
    chartContainer: {
      height: "186px",
    },
    initialOffset: 0,
    verticalOffset: 10,
    labelStyle: {
      fontSize: "8px",
      fontWeight: 600,
      fill: "#333",
    },
    themeStyle: {
      axis: {
        legend: {
          text: {
            fontSize: "8px",
            fontFamily: "Fidelity Sans",
            textColor: "#666666",
            fill: "#666666",
          },
        },
      },
    },
    margin: {
      top: 0,
      right: 35,
      bottom: 35,
      left: 40,
    },
    nodeSize: 6,
    bottomLegendOffset: 30,
    bottomTickPadding: 10,
    leftLegendOffset: -35,
    leftTickPadding: 5,
  },
  web: {
    chartContainer: {
      height: "360px",
    },
    initialOffset: 5,
    verticalOffset: 19,
    labelStyle: {
      fontSize: "15px",
      fontWeight: 600,
      fill: "#333",
    },
    themeStyle: {
      axis: {
        legend: {
          text: {
            fontSize: "12px",
            fontFamily: "Fidelity Sans",
            textColor: "#666666",
            fill: "#666666",
          },
        },
      },
    },
    margin: {
      top: 20,
      right: 60,
      bottom: 90,
      left: 90,
    },
    nodeSize: 9,
    bottomLegendOffset: 55,
    bottomTickPadding: 20,
    leftLegendOffset: -50,
    leftTickPadding: 10,
  },
});

/**
 * For design reasons, we have to derive the x / y scales based on the points that you pass in.
 *
 * @param points
 * @param accessorFn
 */
const deriveScale = (values: number[]): { type: string; min: number; max: number } => {
  const min = Math.min(...values);
  const max = Math.max(...values);
  const pointInterval = (max - min) / values.length;

  // The designs specify that we need to center our points on the graph. We use this factor to pad the scale a bit,
  // both top / bottom and left / right.
  const padFactor = 3;

  // For many types of values, such as standard deviation, having a negative scale doesn't make logical sense.
  // Unless the data itself is telling us that a negative value is a possibility, we tweak the minimum value for the
  // scale in order to exclude potential nonsense results.
  let scaleMin = min - pointInterval * padFactor;
  if (min > 0) {
    scaleMin = Math.max(0, scaleMin);
  }

  return {
    type: "linear",
    min: scaleMin,
    max: max + pointInterval * padFactor,
  };
};

const getTspanElement = (line: string, dyOffset: number, key: number): JSX.Element => {
  return (
    <tspan x={20} dy={dyOffset} key={key}>
      {line}
    </tspan>
  );
};

const getTspanElements = (label: string): JSX.Element[] => {
  const initialOffset = theme.initialOffset;
  const verticalOffset = theme.verticalOffset;

  const lines = label.split("\n");

  return lines.map((line, index) => {
    const dyOffset = index === 0 ? initialOffset : index * verticalOffset;
    return getTspanElement(line, dyOffset, index);
  });
};

/**
 * This function is used to customize how we render points in the ScatterPlot. Our customizations include:
 *  1 - Supporting custom colors.
 *  2 - Supporting the addition of labels.
 */
const renderNode = ({ node, x, y, size, color, blendMode, onMouseEnter, onMouseMove, onMouseLeave, onClick }) => {
  const label = node.data.label ? <text style={theme.labelStyle}>{getTspanElements(node.data.label)}</text> : "";
  return (
    <g transform={`translate(${x},${y})`}>
      <circle
        r={size}
        fill={node.data.color || color}
        style={{ mixBlendMode: blendMode }}
        onMouseEnter={onMouseEnter}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      />
      {label}
    </g>
  );
};

interface ScatterPlotData {
  x: number;
  y: number;
  label: string;
}

const ChartContainer = styled.div`
  height: ${(props) => props.theme.chartContainer.height};
  width: 100%;
`;

type ScatterPlotChartProps = ChartProps<Column, ColorRow, ScatterPlotData, Theme>;

const ScatterPlot = (props: ScatterPlotChartProps) => {
  const xColumn = props.columns.find((column) => column.index === "x");
  const yColumn = props.columns.find((column) => column.index === "y");

  const points = zipWith(props.rows, props.data, (row, point) => ({
    color: row.color,
    label: point.label,
    x: point.x,
    y: point.y,
  }));

  const chartData = {
    id: "Default",
    data: points,
  };

  const xScale = deriveScale(props.data.map((point) => point.x));
  const yScale = deriveScale(props.data.map((point) => point.y));

  theme = themeDefinitions.pickTheme(props.theme);

  return (
    <ThemeProvider theme={theme}>
      <ChartContainer>
        <ResponsiveScatterPlot
          data={[chartData]}
          isInteractive={false}
          margin={theme.margin}
          // See https://github.com/plouc/nivo/issues/674
          xScale={(xScale as unknown) as Scale}
          yScale={(yScale as unknown) as Scale}
          enableGridX={false}
          // Grumble. fontSize and textColor have the intended effect but are not recognized on the type.
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          theme={theme.themeStyle as any}
          nodeSize={theme.nodeSize}
          axisBottom={{
            orient: "bottom",
            legend: xColumn.name.toUpperCase(),
            legendPosition: "middle",
            legendOffset: theme.bottomLegendOffset,
            tickSize: 0,
            tickPadding: theme.bottomTickPadding,
            tickValues: 7,
            renderTick: renderTick(props.theme),
          }}
          axisLeft={{
            orient: "left",
            legend: yColumn.name.toUpperCase(),
            legendPosition: "middle",
            legendOffset: theme.leftLegendOffset,
            tickSize: 0,
            tickPadding: theme.leftTickPadding,
            tickValues: 6,
            renderTick: renderTick(props.theme),
          }}
          renderNode={renderNode}
        />
      </ChartContainer>
    </ThemeProvider>
  );
};

export default ScatterPlot;
