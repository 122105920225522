type StyleValue = string | StyleObject;

interface StyleObject {
  [x: string]: StyleValue;
}

export const getStyles = (width = "70%"): StyleObject => {
  const contentPositioningStyles = {
    bottom: "auto",
    minHeight: "1rem",
    left: "50%",
    position: "fixed",
    right: "auto",
    top: "50%",
    transform: "translate(-50%,-50%)",
    minWidth: "1rem",
  };

  const contentStyles = {
    boxShadow: "0px 2px 6px rgba(0,0,0,0.5)",
    padding: "30px 35px 0 35px",
    width,
  };

  return {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      ...contentPositioningStyles,
      ...contentStyles,
    },
  };
};
