import React from "react";

export interface ModalActionsProps {
  primary: JSX.Element;
  secondary?: JSX.Element;
}

/**
 * This simple component takes two elements -- one corresponding to a secondary action, the other corresponding
 * to a primary action -- and positions them. It should generally be used with EsxPrimaryButton and
 * EsxSecondaryButton components.
 *
 * Usage:
 *
 *    <EsxActionBar
 *      primary={<EsxPrimaryButton>Primary</EsxPrimaryButton>}
 *      secondary={<EsxSecondaryButton>Secondary</EsxSecondaryButton>}
 *    />
 *
 * @param props
 * @constructor
 */
const ModalActions: React.FunctionComponent<ModalActionsProps> = (props) => {
  return (
    <div className="esx-modal__actions">
      {props.secondary}
      {props.primary}
    </div>
  );
};

export default ModalActions;
