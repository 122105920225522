import React from "react";

type RowSpanProps = {
  rowSpan?: number;
};

// helper to avoid having a no-op row_span prop when rowspan = 1
const rowSpanProps = (rowSpan: number): RowSpanProps => (rowSpan > 1 ? { rowSpan } : {});

interface RowSpanWrapperProps {
  rowSpan: number;
  children?: React.ReactNode;
}

const RowSpanWrapper: React.FunctionComponent<RowSpanWrapperProps> = ({ rowSpan, children }) => {
  return <>{rowSpan !== 0 && React.Children.only(children)}</>;
};

export { RowSpanWrapper, RowSpanWrapperProps, rowSpanProps };
