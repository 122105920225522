import React from "react";
import styled from "styled-components";

import { Header, FlexContainer } from "./shared/shared-components";

const StyledFlexContainer = styled(FlexContainer)`
  margin: 50px 0 50px 0;
  .portfolio-name {
    margin-bottom: 60px;
  }

  .portfolio-form-header {
    font-size: 24px;
    line-height: 24px;
    font-weight: normal;

    &--main {
      font-size: 28px;
      line-height: 28px;
      padding: 5px;
    }
  }
`;

const HeaderContent = () => (
  <StyledFlexContainer>
    <Header Tag="h1" className="portfolio-form-header--main" text="Enter symbols and weights" />
    <Header Tag="h3" className="portfolio-form-header" text="Find a security by name or symbol." />
    <Header Tag="h3" className="portfolio-form-header" text="Then, add its weight as a percentage." />
  </StyledFlexContainer>
);

export default HeaderContent;
