import React, { FunctionComponent } from "react";

const TextPrimary: FunctionComponent = (props) => {
  return (
    <p className="esx-text esx-text__primary" {...props}>
      {props.children}
    </p>
  );
};

export default TextPrimary;
