import React from "react";
import styled from "styled-components";

import iconEdit from "../../images/icon_edit.svg";
import iconDelete from "../../images/icon_delete.svg";

import { GridContainer, RowCell, FlexContainer } from "./shared/shared-components";
import { Holding } from "./models/holding";
import HoldingRowWeightCell from "./HoldingRowWeightCell";

const StyledGridContainer = styled(GridContainer)`
  border-bottom: solid 0.5px #ccc;

  .holding-row {
    padding: 10px;
    font-size: 16px;

    &__edit {
      margin-right: 10px;
    }

    &__ticker {
      border-left: solid 10px #adc7df;
    }

    &__trash {
      margin-right: 10px;
    }
  }

  .holding-row:nth-child(1) {
    -ms-grid-column: 1;
  }

  .holding-row:nth-child(2) {
    -ms-grid-column: 2;
  }

  ${FlexContainer} {
    -ms-grid-column: 3;
  }
`;

interface HoldingRowProps {
  holding: Holding;
  updateHoldingWeight: (holding: Holding, newWeight: number) => void;
  removeHolding: (holding: Holding) => void;
}

const HoldingRow: React.FunctionComponent<HoldingRowProps> = ({ holding, updateHoldingWeight, removeHolding }) => {
  const [disabled, setDisabled] = React.useState(true);

  const handleChange = (e) => {
    updateHoldingWeight(holding, e.target.value);
  };

  const editRow = () => {
    setDisabled(false);
  };

  const deleteRow = () => {
    removeHolding(holding);
  };

  const handleBlur = () => {
    setDisabled(true);
  };

  return (
    <StyledGridContainer columns={3} className="holdingContainer">
      <RowCell className="holding-row holding-row__ticker" text={holding.ticker} />
      <RowCell className="holding-row" text={holding.name} />
      <FlexContainer className="holding-row-container" direction="row" justify="flex-end" align="center">
        <HoldingRowWeightCell
          className="holding-row holding-row__weight"
          value={holding.weight}
          disabled={disabled}
          errors={[holding.errors.weight]}
          onChange={(e) => handleChange(e)}
          onBlur={handleBlur}
          align="right"
        />
        <FlexContainer className="holding-row__edit" onClick={editRow}>
          <img src={iconEdit} alt="edit icon" />
        </FlexContainer>
        <FlexContainer className="holding-row__trash" onClick={deleteRow}>
          <img src={iconDelete} alt="delete icon" />
        </FlexContainer>
      </FlexContainer>
    </StyledGridContainer>
  );
};

export default HoldingRow;
