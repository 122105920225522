import React from "react";

import { ChartTheme, Theme, ThemeDefinitions } from "../Theme";
import { hyphenate } from "../../../javascripts/hyphenation-utilities";

const themeDefinitions = new ThemeDefinitions<ChartTheme>({
  web: {
    fontFamily: "Fidelity Sans",
    fontSize: "12px",
    textColor: "#666666",
    fill: "#666666",
  },
  pdf: {
    fontFamily: "Fidelity Sans",
    fontSize: "8px",
    textColor: "#666666",
    fill: "#666666",
  },
});

const splitLabel = (label: string, maxLabelWidth) => {
  if (!label) {
    return [];
  }

  // Split label by words and spaces
  const words = label.split(/(\s+)/);

  // Loop through words in label and determine if current word and next word can fit. If so, combine. If not, continue
  // iterating. This will end up with an array of strings that will fit within max length of tick label, thus preventing
  // any overlap.
  let i = 0;
  while (words[i + 1]) {
    if (words[i + 1] && words[i].length + words[i + 1].length <= maxLabelWidth) {
      words[i] += words[i + 1];
      words.splice(i + 1, 1);
    } else {
      i++;
    }
  }

  // Determine if any of the individual words exceed the max label width. If so, split them to prevent
  // overlap. While not ideal from a UX perspective it's necessary for some of the larger charts.
  return words
    .flatMap((word) => {
      return word.length < maxLabelWidth ? word.trim() : hyphenate(word.trim(), maxLabelWidth);
    })
    .filter((word) => word);
};

const tickWordElement = (word: string, dyOffset: number, key = 0): JSX.Element => {
  return (
    <tspan x={0} dy={dyOffset} key={key}>
      {word}
    </tspan>
  );
};

const renderWords = (words: string[], initialOffset: number, verticalOffset: number) => {
  return words.map((word, ix) => {
    const dyOffset = ix === 0 ? initialOffset : verticalOffset;
    return tickWordElement(word, dyOffset, ix);
  });
};

const renderTick = (theme, valueFormatter = (value) => value) => {
  // Line up the props to be any to match Nivo ScatterPlot.renderTick
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (props: any): any => {
    const { opacity, textAnchor, textBaseline, textX, textY, value, x, y, rotate } = props;
    return (
      <g transform={`translate(${x},${y})`} style={{ opacity }}>
        <text
          alignmentBaseline={textBaseline}
          style={themeDefinitions.pickTheme(theme)}
          textAnchor={textAnchor}
          transform={`translate(${textX},${textY}) rotate(${rotate})`}
        >
          {valueFormatter(value)}
        </text>
      </g>
    );
  };
};

// Function which will split given data into multiple tables if theme is PDF and number of rows is greater than what can
// fit on page.
const getTablesData = (data, theme: Theme, columns, width) => {
  // Initialize max number of rows. This can decrease depending on how many long fund names there are.
  let maxNumRows = 19;

  if (theme !== Theme.PDF || !(data.length > maxNumRows)) {
    return [data];
  } else {
    // Each letter takes roughly 3px of space.
    const characterWidth = 3;
    // Calculate the width all columns, excluding name, take. We use the column header rather than the cell value
    // because the header is longer than the value itself.
    const columnsWidthExcludingName = columns
      .map((c) => {
        // If it's a color column, we know the fixed width. If it's name, we can ignore because we're calculating
        // the space remaining for that column. Otherwise, we take the header length multiply by the average character
        // width and then add the fixed padding all columns have.
        if (c.index === "color") {
          return 10;
        } else if (c.index === "name") {
          return 0;
        } else {
          const headerPadding = 15;
          return c.name.length * characterWidth + headerPadding;
        }
      })
      .reduce(function (a, b) {
        return a + b;
      });

    // Subtract from the overall given width the width of the columns excluding name. Then divide that value by
    // the width of a character to determine how many characters can fit within that space. Filter for fund names which
    // will require wrapping.
    const numLongNames = data
      .map((d) => {
        return d.name.length;
      })
      .filter((l) => l > (width - columnsWidthExcludingName) / characterWidth).length;

    // Decrease max number of rows by number of funds with long names divided by 8. A typical row's height is 24px, but
    // when it wraps, it becomes 27px. 8 "wrapping" rows would add up to an extra row.
    maxNumRows -= Math.ceil(numLongNames / 8);

    const tablesData = [];
    const numTables = data.length / maxNumRows;
    for (let i = 0; i < numTables; i++) {
      tablesData.push(data.slice(i * maxNumRows, maxNumRows * (i + 1)));
    }

    return tablesData;
  }
};
export { splitLabel, tickWordElement, renderWords, renderTick, getTablesData };
