import React, { useState } from "react";

import { PortfolioCrudProps } from "./HoldingsCrudContainer";
import HoldingsForm from "./HoldingsForm";
import PortfolioNameForm from "./PortfolioNameForm";
import HeaderContent from "./HeaderContent";

const PortfolioWizard: React.FunctionComponent<PortfolioCrudProps> = ({
  portfolio,
  addHolding,
  updateHoldingWeight,
  removeHolding,
  setName,
  savePortfolio,
  getInvestmentVehicles,
  holdingsClass,
  crudMode,
}) => {
  const [page, setPage] = useState(1);

  const nextPage = (event: React.FormEvent) => {
    event.preventDefault();
    if (!portfolio.errors.name) {
      setPage(page + 1);
    }
  };

  // Need to convert name error to an array because Errors component requires one.
  const nameErrors = portfolio.errors.name ? [portfolio.errors.name] : [];

  return page === 1 ? (
    <PortfolioNameForm
      name={portfolio.name}
      holdingsClass={holdingsClass}
      onChange={setName}
      errors={nameErrors}
      onSubmit={nextPage}
    />
  ) : (
    <>
      <HeaderContent />
      <HoldingsForm
        portfolio={portfolio}
        addHolding={addHolding}
        updateHoldingWeight={updateHoldingWeight}
        removeHolding={removeHolding}
        getInvestmentVehicles={getInvestmentVehicles}
        submit={savePortfolio}
        crudMode={crudMode}
      />
    </>
  );
};

export default PortfolioWizard;
