import React from "react";
import styled from "styled-components";

import { cellStyles } from "./Cell";
import { DataCellProps } from "./DataCell";
import { ExpanderToggle } from "./ExpanderToogle";

const ExpanderDataCell = ({ className, cell }: DataCellProps): JSX.Element => {
  return (
    <td className={className} {...cell.getCellProps()}>
      <ExpanderToggle row={cell.row} />
    </td>
  );
};

const StyledExpanderDataCell = styled(ExpanderDataCell)`
  ${cellStyles};
  width: 24px;
  padding: 2px;
`;

export { StyledExpanderDataCell as ExpanderDataCell };
