// tslint:disable:max-classes-per-file

import React from "react";
import * as ReactModal from "react-modal";

import { getStyles } from "../util/modal-helpers";
import eventBus from "../util/event-bus";

// See https://reactcommunity.org/react-modal/accessibility/
ReactModal.setAppElement(".js-application-page");

export interface SharedModalProps {
  contentLabel: string;
  className?: string;
  width?: string;
}

interface ReactModalProps extends SharedModalProps {
  body: JSX.Element;
  actions?: JSX.Element;
  header?: JSX.Element;
}

interface ControlledModalProps extends ReactModalProps {
  isOpen: boolean;
  onRequestClose?: () => void;
}

export interface UncontrolledModalProps extends ReactModalProps {
  openTriggers: string[];
  closeTriggers?: string[];
  onUpdate?: () => void;
}

export interface WithTriggersModalState {
  isOpen: boolean;
}

class ControlledModal extends React.Component<ControlledModalProps> {
  public render() {
    const { header, body, actions, width } = { ...this.props };
    const className = this.props.className || ""; // If null, this explodes ReactModal.

    return (
      <ReactModal {...this.props} style={getStyles(width)} className={className}>
        <div className={`esx-modal`}>
          {header ? <div className="esx-modal__header">{header}</div> : ""}
          <div className="esx-modal__body">{body}</div>
          {actions || ""}
        </div>
      </ReactModal>
    );
  }
}

const withTriggers = (WrappedComponent: React.ComponentType<ControlledModalProps>) => {
  return class extends React.Component<UncontrolledModalProps, WithTriggersModalState> {
    private readonly eventsToListenFor = ["click", "keyup"];

    constructor(props) {
      super(props);
      this.state = {
        isOpen: false,
      };

      this.close = this.close.bind(this);
      this.open = this.open.bind(this);
    }

    render() {
      return (
        <WrappedComponent
          onRequestClose={() => this.setState({ isOpen: false })}
          isOpen={this.state.isOpen}
          {...this.props}
        />
      );
    }

    componentDidMount(): void {
      this.addEventListeners();

      eventBus.on("renderModalTrigger", (data) => {
        this.removeEventListeners();
        this.addEventListeners();
      });
    }

    componentWillUnmount(): void {
      this.removeEventListeners();
      eventBus.remove("renderModalTrigger");
    }

    componentDidUpdate(): void {
      const onUpdate = this.props.onUpdate;

      if (onUpdate) {
        onUpdate.call(this);
      }
    }

    private get closeTriggers(): string[] {
      return this.props.closeTriggers || [];
    }

    private get modalSelector(): string {
      return ".ReactModalPortal";
    }

    private get openTriggers(): string[] {
      return this.props.openTriggers;
    }

    private addEventListeners(): void {
      this.openTriggers.forEach(this.mountListeners(this.open));
      this.mountListeners(this.close)(this.modalSelector);
    }

    private removeEventListeners(): void {
      this.openTriggers.forEach(this.unmountListeners(this.open));
      this.unmountListeners(this.close)(this.modalSelector);
    }

    private close(event) {
      if (!this.isIgnorableKeyboardEvent(event) && this.isCloseTrigger(event)) {
        this.setState({ isOpen: false });
      }
    }

    private open() {
      if (!this.isIgnorableKeyboardEvent(event)) {
        this.setState({ isOpen: true });
      }
    }

    private isCloseTrigger(event) {
      return this.closeTriggers.some((trigger) => {
        return document.querySelector(trigger)?.contains(event.target as HTMLElement);
      });
    }

    private mountListeners(method) {
      return (selector: string) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((element) => {
          this.eventsToListenFor.forEach((event) => {
            element.addEventListener(event, method);
          });
        });
      };
    }

    private unmountListeners(method) {
      return (selector: string) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((element) => {
          this.eventsToListenFor.forEach((event) => {
            element.removeEventListener(event, method);
          });
        });
      };
    }

    private isIgnorableKeyboardEvent(event) {
      return this.isEventKeyup(event) && event.key !== "Enter";
    }

    private isEventKeyup(event) {
      return event && event.type === "keyup";
    }
  };
};

const UncontrolledModal = withTriggers(ControlledModal);

/**
 * ControlledModal is a controlled component that takes isOpen and onRequestClose arguments in addition to the content
 * arguments. UncontrolledModal is an uncontrolled components which takes CSS selectors that, when clicked or otherwise
 * activated via the enter key, open or close the modal automatically.
 *
 * For a discussion of controlled vs. uncontrolled components, see here:
 *  https://itnext.io/controlled-vs-uncontrolled-components-in-react-5cd13b2075f9
 *
 * The primary use case for the UncontrolledModal is, at the moment, when using the modal from ActionComponent / Rails.
 * It's expected that in React-land we'll default to using the Controlled version of the modal and, depending on what
 * use cases emerge, we may axe this factory function entirely.
 *
 * Usage of the ControlledModal is as so:
 *
 *   <Modal
 *     contentLabel="World's best modal"
 *     isOpen={this.state.isOpen}
 *     onRequestClose={() => {this.setState({ isOpen: false })}}
 *     width="600px"
 *     header={<TextSubTitle as="h1">World's best modal</TextSubTitle>}
 *     body={<TextPrimary>With the best content, Woohooo!!</TextPrimary>}
 *     actions={
 *       <ModalActions
 *         primary={<PrimaryButton>Primary</PrimaryButton>}
 *         secondary={<SecondaryButton>Secondary</SecondaryButton>}
 *       />
 *     }
 *   />
 *
 * @param props
 * @constructor
 */
const Modal: React.FunctionComponent<UncontrolledModalProps | ControlledModalProps> = (props) => {
  const isStateful = !!(props as UncontrolledModalProps).openTriggers;
  return isStateful ? (
    <UncontrolledModal {...(props as UncontrolledModalProps)} />
  ) : (
    <ControlledModal {...(props as ControlledModalProps)} />
  );
};

export default Modal;
