import React from "react";

import { Format } from "../../shared/util/data-formatters";

import { CellProps } from "./Cell";
import { SimpleDataCell } from "./SimpleDataCell";
import { ExpanderDataCell } from "./ExpanderDataCell";
import { ColorDataCell } from "./ColorDataCell";
import { RowSpanWrapperProps } from "./RowSpanWrapper";

export interface DataCellProps extends CellProps, RowSpanWrapperProps {
  // cell is are a complicated mess of react-table types - we give up trying to get it right
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cell: any;
}

const DataCell = (props: DataCellProps): JSX.Element => {
  const { column } = props;
  let CellClass;
  if (column.format === Format.Color) {
    CellClass = ColorDataCell;
  } else if (column.format === Format.Children) {
    CellClass = ExpanderDataCell;
  } else {
    CellClass = SimpleDataCell;
  }
  return <CellClass {...props} />;
};

export { DataCell };
