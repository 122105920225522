import React, { FormEvent } from "react";

import { FlexContainer, Header, InvertedButton } from "./shared/shared-components";
import PortfolioNameInput from "./PortfolioNameInput";

export interface PortfolioNameProps {
  name: string;
  holdingsClass: string;
  errors: string[];
  onChange: (name: string, validate: boolean) => void;
  onSubmit: (event: FormEvent) => void;
}

const PortfolioNameForm: React.FunctionComponent<PortfolioNameProps> = ({
  name,
  holdingsClass,
  onChange,
  errors,
  onSubmit,
}) => {
  const buttonCSS = {
    marginTop: "28px",
  };

  const headerCss = {
    marginTop: "12px",
  };

  const errorsExist = (errors && errors.length > 0) || !name;

  return (
    <FlexContainer className="portfolio-name" direction="column">
      <form onSubmit={onSubmit}>
        <FlexContainer style={headerCss} className="portfolio-name" direction="column">
          <Header Tag={"h1"} className="portfolio-name__header" text={`Please name the ${holdingsClass}`} />
        </FlexContainer>
        <PortfolioNameInput name={name} errors={errors} onChange={onChange} holdingsClass={holdingsClass} />
        <FlexContainer style={buttonCSS} className="portfolio-name" direction="column">
          <InvertedButton type="Submit" disabled={errorsExist}>
            Next
          </InvertedButton>
        </FlexContainer>
      </form>
    </FlexContainer>
  );
};

export default PortfolioNameForm;
