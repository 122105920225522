import styled from "styled-components";
import React from "react";

import { CellProps, paddedCellStyle } from "./Cell";

const SimpleHeaderCell = ({ className, column }: CellProps): JSX.Element => {
  return (
    <th className={className} {...column.getHeaderProps()}>
      {column.render("Header")}
    </th>
  );
};

const StyledSimpleHeaderCell = styled(SimpleHeaderCell)`
  ${paddedCellStyle};
`;

export { StyledSimpleHeaderCell as SimpleHeaderCell };
