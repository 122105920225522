import React from "react";
import { Chip } from "@nivo/tooltip";
import zipWith from "lodash.zipwith";

import { ChartProps, Column, Row } from "../models/chart-props";
import { format, FormatValue } from "../../shared/util/data-formatters";
import { Theme } from "../Theme";

interface ColorSource {
  name: string;
  color: string;
}

type NivoTooltip = (
  headerLabel: number | string,
  tooltipLabel: number | string,
  value: FormatValue,
  color: string
) => JSX.Element;

interface NivoData<C extends Column, R extends Row, D> {
  data: D[];
  columnsByName: Record<string, C>;
  rowsByName: Record<string, R>;
  tooltip: NivoTooltip;
}

export function NivoData<C extends Column, R extends Row, D, T extends Theme>(
  props: ChartProps<C, R, D, T>
): NivoData<C, R, D> {
  const columnsByName: Record<string, C> = props.columns.reduce((acc, column) => {
    acc[column.name] = column;
    return acc;
  }, {});

  const rowsByName: Record<string, R> = props.rows.reduce((acc, row) => {
    acc[row.name] = row;
    return acc;
  }, {});

  // props.data rows are keyed by index. Nivo wants it keyed by the name
  const data = zipWith(props.rows, props.data, (row, dataRow) => {
    const rowData: { name: string; color?: string; [key: string]: any } = { name: row.name };
    if (row.color) {
      rowData.color = row.color;
    }
    // FPC1-1928 need 2 separate colors for FI charts where bars are color coded by both row and column
    if (row.portfolio_color) {
      rowData.portfolio_color = row.portfolio_color;
    }
    if (row.model_portfolio_benchmark_color) {
      rowData.model_portfolio_benchmark_color = row.model_portfolio_benchmark_color;
    }

    return Object.keys(columnsByName).reduce((acc, label) => {
      const accessor = columnsByName[label].index;
      acc[label] = dataRow[accessor];
      return acc;
    }, rowData);
  });

  const tooltip: NivoTooltip = (columnName, tooltipLabel, value, color) => {
    const header = columnsByName[columnName];

    // Cribbed from the Nivo implementation - adapted to use the formatter
    return (
      <div
        style={{
          whiteSpace: "pre",
          display: "flex",
          alignItems: "center",
        }}
      >
        {<Chip color={color} style={{ marginRight: 7 }} />}
        {
          <span>
            {tooltipLabel}: <strong>{format(header, value)}</strong>
          </span>
        }
      </div>
    );
  };

  return {
    data,
    columnsByName,
    rowsByName,
    tooltip,
  };
}
