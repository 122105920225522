import { DataTableColumn } from "../../analysis/DataTable";

import { Format } from "./data-formatters";

export enum Alignment {
  Left = "left",
  Right = "right",
}

export const columnAlignment = (column: DataTableColumn): Alignment => {
  if (column.align !== undefined) {
    return column.align;
  }

  switch (column.format) {
    case Format.Text:
    case Format.TextLink:
      return Alignment.Left;
      break;
    default:
      return Alignment.Right;
  }
};
