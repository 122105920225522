import React from "react";
import styled from "styled-components";

import colors from "../../../styles/includes/_colors.scss";

// Note: This file contains components that are shared within the portfolio_input. They'd need some refinement
// to get shared across multiple frontend packages. The main concern is that components styled using FlexContainer,
// GridCell, etc., are necessarily aware of their context. Is this cleaner than just specifying the layout in
// a container component?

const FlexContainer = styled("div")`
  display: flex;
  flex-shrink: 0;
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  flex-direction: ${(props) => props.direction};
}
`;

FlexContainer.defaultProps = {
  align: "center",
  justify: "center",
  direction: "column",
};

/*
  Note: To maintain compatibility with IE11, you will need to manually assign each child cell to its column in a grid.
  See HoldingsHeaders or HoldingRow for example syntax.

  See comment at the top of this file for additional color on these shared components. We should deliberate the
  approach taken here before sharing these grid components across other frontend packages.
 */
const GridContainer = styled("div")`
  display: -ms-grid;
  display: grid;
  grid-gap: ${(props) => props.gap};
  -ms-grid-columns: ${(props) => [...Array(props.columns).keys()].map(() => "1fr ")};
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
`;

GridContainer.defaultProps = {
  gap: "10px",
  columns: 12,
};

const GridCell = styled("div")`
  -ms-grid-column: ${(props) => props.start};
  -ms-grid-column-span: ${(props) => props.end - props.start};
  grid-column: ${(props) => props.start} / ${(props) => props.end};
  padding: ${(props) => props.padding};
`;

GridCell.defaultProps = {
  start: 1,
  end: 2,
  padding: "10px",
};

const PrimaryButton = styled.button`
  margin: ${(props) => props.margin};
  min-width: ${(props) => props.width};
  height: 45px;
  padding: ${(props) => props.padding};
  border-radius: 100px;
  color: ${colors.actionBlue};
  background-size: cover;
  font-size: 14px;
  text-align: center;
  border: 1px solid ${colors.actionBlue};
  transition: background-color 0.1s ${colors.gallery};

  &:hover {
    color: #fff;
    cursor: pointer;
    background-color: ${colors.actionBlue};
  }

  &:focus {
    outline-width: 0;
  }
`;

PrimaryButton.defaultProps = {
  margin: "10px",
  width: "250px",
  padding: "10px",
};

const InvertedButton = styled(PrimaryButton)`
  min-width: 300px;
  color: #fff;
  background-color: ${colors.actionBlue};

  &:hover {
    background-color: #0b3655;
  }
`;

const Header = (props) => {
  const { Tag, className, text } = props;

  return <Tag className={className}>{text}</Tag>;
};

const RowCell = (props) => {
  const { className, text } = props;

  return <div className={className}>{text}</div>;
};

FlexContainer.displayName = "FlexContainer";
GridContainer.displayName = "GridContainer";
GridCell.displayName = "GridCell";
PrimaryButton.displayName = "PrimaryButton";
InvertedButton.displayName = "InvertedButton";
Header.displayName = "Header";
RowCell.displayName = "RowCell";

export { FlexContainer, GridContainer, GridCell, PrimaryButton, InvertedButton, Header, RowCell };
