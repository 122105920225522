import React from "react";

const PrimaryButton = (props) => {
  const className = `esx-button esx-button__primary ${props.className}`;
  return (
    <button {...props} className={className}>
      {props.children}
    </button>
  );
};

export default PrimaryButton;
