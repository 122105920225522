import styled, { css } from "styled-components";
import React from "react";

import colors from "../../../styles/includes/_colors.scss";
import { Alignment, columnAlignment } from "../../shared/util/alignment-helpers";

import { CellProps, paddedCellStyle } from "./Cell";
import { SortIndicator } from "./SortIndicator";

export const SORTER_INDICATOR_WIDTH = 25;

export enum SortDirection {
  None,
  Neutral,
  Descending,
  Ascending,
}

const SortableHeaderCellContainer: React.FunctionComponent<CellProps> = ({ className, column }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  let sortDirection = SortDirection.None;
  if (column.isSorted) {
    sortDirection = column.isSortedDesc ? SortDirection.Descending : SortDirection.Ascending;
  } else if (isHovered) {
    sortDirection = SortDirection.Neutral;
  }

  const mouseEnter = () => setIsHovered(true);
  const mouseLeave = () => setIsHovered(false);

  const cellProps = { className, column, sortDirection, isHovered, mouseEnter, mouseLeave };

  return <StyledSortableHeaderCell {...cellProps} />;
};

interface SortableHeaderCellProps {
  className: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: any;
  sortDirection: SortDirection;
  isHovered: boolean;
  mouseEnter: () => void;
  mouseLeave: () => void;
}

const SortableHeaderCell = (props: SortableHeaderCellProps): JSX.Element => {
  const { className, column, sortDirection, mouseEnter, mouseLeave } = props;

  return (
    <th className={className} {...column.getSortByToggleProps()} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <div>
        <span className={"label"}>{column.render("Header")}</span>
        <SortIndicator sortDirection={sortDirection} />
      </div>
    </th>
  );
};

const underlinedColor = ({ isHovered, sortDirection }: SortableHeaderCellProps): string => {
  if (isHovered) {
    return colors.actionBlue;
  } else if (sortDirection !== SortDirection.None) {
    return colors.mineShaft;
  } else {
    return "transparent";
  }
};

// We use flex box to align the vertically align the centers of the label and the SortIndicator
// This overrides the text-align styling that paddedCellStyle would normally give us.
// flex-end justifies everything right
// space-between will left align the label and right align the SortIndicator
const justifyContent = ({ column }: SortableHeaderCellProps): string => {
  return columnAlignment(column) === Alignment.Right ? "flex-end" : "space-between";
};

// Use box-shadow not border for the underline to prevent changing the header dimensions on hover
// Without it the header text will "jump" on hover
const StyledSortableHeaderCell = styled(SortableHeaderCell)`
  ${paddedCellStyle};
  ${(props: SortableHeaderCellProps) =>
    css`
      box-shadow: inset 0 -2px ${underlinedColor(props)};
    `};

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${justifyContent};
  }

  ${SortIndicator} {
    width: ${SORTER_INDICATOR_WIDTH}px;
  }
`;

export { SortableHeaderCellContainer as SortableHeaderCell };
