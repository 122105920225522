import React, { ButtonHTMLAttributes } from "react";

import Modal, { SharedModalProps, UncontrolledModalProps } from "../overlays/Modal";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import { bootstrapUJSComponents, renderRawContent } from "../util/raw-content-helpers";
import ModalActions from "../overlays/ModalActions";
import TextSubTitle from "../typography/TextSubTitle";

const CLOSE_TRIGGER = "js-close-trigger";

enum ActionType {
  Close = "close",
  Link = "link",
  Delete = "delete",
}

interface ButtonActionArgs {
  type: ActionType;
  path: string;
}

interface ButtonArgs {
  content: string;
  action: ButtonActionArgs | string;
}

interface RailsModalProps extends SharedModalProps {
  body: string;
  header?: string;
  primaryAction?: ButtonArgs;
  secondaryAction?: ButtonArgs;
  openTriggers: string[];
}

const constructButtonElement = (
  Button: React.ComponentType<ButtonHTMLAttributes<HTMLButtonElement>>,
  args: ButtonArgs
): JSX.Element => {
  if (!args) {
    return null;
  }

  const actionType = typeof args.action === "string" ? args.action : args.action.type;

  switch (actionType) {
    case ActionType.Close:
      return <Button className={CLOSE_TRIGGER}>{args.content}</Button>;
    case ActionType.Link:
      return (
        <a href={(args.action as ButtonActionArgs).path}>
          <Button>{args.content}</Button>
        </a>
      );
    case ActionType.Delete:
      return (
        <a data-method="delete" href={(args.action as ButtonActionArgs).path}>
          <Button>{args.content}</Button>
        </a>
      );
    default:
      throw new Error("Unrecognized action type");
  }
};

const actionsElement = ({ primaryAction, secondaryAction }: RailsModalProps) => {
  if (!primaryAction && !secondaryAction) {
    return null;
  }

  return (
    <ModalActions
      primary={constructButtonElement(PrimaryButton, primaryAction)}
      secondary={constructButtonElement(SecondaryButton, secondaryAction)}
    />
  );
};

const withRailsAdapter = (WrappedComponent: React.ComponentType<UncontrolledModalProps>) => {
  return (props: RailsModalProps) => {
    const originalProps = {
      ...props,
    };

    const header = originalProps.header ? <TextSubTitle as={"h1"}>{originalProps.header}</TextSubTitle> : null;

    const mergedProps = {
      ...originalProps,
      ...{
        onUpdate() {
          bootstrapUJSComponents(this);
        },
        header,
        body: renderRawContent(originalProps.body),
        closeTriggers: [`.${CLOSE_TRIGGER}`],
        actions: actionsElement(originalProps),
      },
    };

    return <WrappedComponent {...mergedProps} />;
  };
};

const RailsModal = withRailsAdapter(Modal);

export default RailsModal;
