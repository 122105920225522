import { ValidationError } from "yup";

const yupErrorsToHash = (error: ValidationError) => {
  const errors = error.inner;
  const errorsHash = {};
  errors.forEach((e) => {
    errorsHash[e.path] = e.message;
  });
  return errorsHash;
};

const validate = (model, schema) => {
  model.errors = {};
  try {
    schema.validateSync(model, { abortEarly: false });
  } catch (error) {
    model.errors = yupErrorsToHash(error);
  }
};

export { validate };
