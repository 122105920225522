import React, { MouseEvent } from "react";

import closeIcon from "../../../images/icon_cross.svg";
import "./Popover.scss";
import withDelayedRender from "../util/with-delayed-render";

import AnchoredOverlay, { CommonOverlayProps, Trigger } from "./AnchoredOverlay";

/**
 * Generally, these options can and should be safely ignored. They're only useful in edgey situations when using the
 * Popover from a Rails context. Generally, using the component defaults is fine.
 */
interface OverrideDefaultsProps {
  // Whether the popover is by default open. Defaults to false.
  defaultToOpen?: boolean;
  // Whether clicking outside the popover closes it. Defaults to false.
  clickOutsideCloses?: boolean;
}

export interface PopoverProps extends CommonOverlayProps {
  showCloseIcon?: boolean;
  overrideDefaultBehavior?: OverrideDefaultsProps;
  // Forces the popover to close. This has only been tested in the "fire and forget, permanently close the
  // popover" case. I suspect the property would work for controlling the open state of the popover from
  // outside entirely -- but this suspicion would need to be validated and there isn't a use case for it for now.
  forceClose?: boolean;
}

interface PopoverState {
  isOpen: boolean;
}

class Popover extends React.Component<PopoverProps, PopoverState> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: !!(props.overrideDefaultBehavior || {}).defaultToOpen,
    };
    this.setIsOpen = this.setIsOpen.bind(this);
    this.onCloseTriggerClick = this.onCloseTriggerClick.bind(this);
    this.onRequestClose = this.onRequestClose.bind(this);
  }

  render() {
    const controlledProps = {
      trigger: Trigger.MANUAL,
      open: this.state.isOpen,
      onRequestClose: this.onRequestClose,
    };

    const addlProps = {
      interactive: true,
      triggerElement: this.triggerElement,
      content: this.contentElement,
      controlled: controlledProps,
    };

    return <AnchoredOverlay {...this.props} {...addlProps} />;
  }

  componentDidUpdate(): void {
    const forceClose = this.props.forceClose;
    if (typeof forceClose !== "undefined" && this.state.isOpen) {
      this.setIsOpen(false);
    }
  }

  private get behaviorOverrides() {
    return this.props.overrideDefaultBehavior || {};
  }

  private get triggerElement() {
    return (
      <span style={{ display: "inline-block" }} className={"popover__trigger"} onClick={() => this.setIsOpen(true)}>
        {this.props.triggerElement}
      </span>
    );
  }

  private get contentElement() {
    const contentClassName = `popover__content ${this.props.showCloseIcon ? "popover__content--has-close-icon" : ""}`;

    return (
      <div className={"popover"}>
        {this.props.showCloseIcon ? (
          <button className={"popover__close-trigger"} type={"button"} onClick={this.onCloseTriggerClick}>
            <img src={closeIcon} alt={"Close Popover"} />
          </button>
        ) : (
          ""
        )}
        <div className={contentClassName}>{this.props.content}</div>
      </div>
    );
  }

  private onCloseTriggerClick(event: MouseEvent) {
    event.preventDefault();
    this.setIsOpen(false);
  }

  private onRequestClose() {
    const isOpen =
      typeof this.behaviorOverrides.clickOutsideCloses !== "undefined"
        ? !this.behaviorOverrides.clickOutsideCloses
        : true;
    this.setIsOpen(isOpen);
  }

  private setIsOpen(isOpen: boolean) {
    this.setState({ isOpen });
  }
}

// If the popover is defaulted to an open state and renders before the layout is fully ready, it can find itself
// positioned improperly on the screen. We set a delay in the one context where we've seen this.
export default withDelayedRender(Popover);
