import "./polyfills";

// Stimulus boiler plate
import "controllers";

require("@rails/ujs").start();
require("turbolinks").start();
// require("@rails/activestorage").start()

// Copy all static images under ../images to the output folder.
// Reference them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
require.context("../images", true);

// include all the css
import("styles/application.scss");

var componentRequireContext = require.context("components", true, /^(?!.*(?:stories.*$)).+$/);

var ReactRailsUJS = require("react_ujs");

ReactRailsUJS.useContext(componentRequireContext);
