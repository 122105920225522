import cloneDeep from "lodash.clonedeep";
import { object, string, number } from "yup";

import { validate } from "../../shared/util/yup-helpers";

import { Holding } from "./holding";

type PortfolioErrors = {
  name?: string;
  totalWeight?: string;
  holdings?: string[];
};

class Portfolio implements Validatable {
  public id?: string;
  private _name?: string;
  private _holdings?: Holding[];
  public totalWeight: number;
  public errors: PortfolioErrors;

  constructor({ id = null, name = "", holdings = [], errors = {} }) {
    this.id = id;
    this._name = name;
    this._holdings = holdings;
    this.holdings = holdings.map((h) => {
      return new Holding(h);
    });
    this.errors = errors;
  }

  static SCHEMA = object({
    name: string().max(150).required("Please enter a name for your portfolio.").ensure(),
    totalWeight: number()
      .typeError("Unable to calculate total weight due to invalid holdings")
      .test({
        name: "lessThan100",
        test: function (value) {
          return value > 100
            ? this.createError({
                message: `Portfolio overweight by ${value - 100}%`,
                path: "totalWeight",
              })
            : true;
        },
      }),
  });

  get name() {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
    this.validate();
  }

  get holdings() {
    return this._holdings;
  }

  set holdings(holdings: Holding[]) {
    this._holdings = holdings;
    this.validate();
  }

  cloneDeep() {
    return cloneDeep(this);
  }

  validate() {
    this.totalWeight = this.calculateTotalWeight();

    validate(this, Portfolio.SCHEMA);

    this.holdings.forEach((h) => {
      h.validate();
    });
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      holdings: this.holdings,
    };
  }

  private calculateTotalWeight() {
    return this.holdings
      .map((x) => x.weight)
      .reduce((a, b) => {
        // we expect weight to be numbers, but this ensures it
        return Number(a) + Number(b);
      }, 0);
  }
}

export { PortfolioErrors, Portfolio };
