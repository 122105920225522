import React from "react";
import styled from "styled-components";

import expandIcon from "../../../images/expand_icon.svg";

const ExpanderToggle = ({ className, row }): JSX.Element => {
  return (
    row.canExpand && (
      <span className={className} {...row.getToggleRowExpandedProps()}>
        <img src={expandIcon} />
      </span>
    )
  );
};

const StyledExpanderToggle = styled(ExpanderToggle)`
  cursor: pointer;

  img {
    transform: rotate(${({ row }) => (row.isExpanded ? "0deg" : "-90deg")});
  }
`;

export { StyledExpanderToggle as ExpanderToggle };
