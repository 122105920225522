import React from "react";

import { HeaderElementProps } from "../models/header-element-props";

const TextSubTitle: React.FunctionComponent<HeaderElementProps> = (props) => {
  const As = props.as || "p";
  return (
    <As className="esx-text esx-text__sub-title" {...props}>
      {props.children}
    </As>
  );
};

export default TextSubTitle;
