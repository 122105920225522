import React from "react";
import styled from "styled-components";

import { percentage } from "../shared/util/data-formatters";
import Errors from "../shared/Errors";
import colors from "../../styles/includes/_colors.scss";

const StyledInput = styled.input`
  text-align: ${(props) => (props.align ? props.align : "left")};
  padding-right: 10px;
  border-radius: 10px;
  font-size: 16px;
  border: solid 1px #b8b8b8;
  height: 40px;
  width: 100%;
  outline-width: 0;

  :disabled {
    background: none;
    border: none;
    color: ${colors.black};
  }
`;

StyledInput.defaultProps = {
  disabled: true,
};

StyledInput.displayName = "StyledInput";

interface HoldingRowWeightCellProps {
  className: string;
  value: string | number;
  disabled: boolean;
  errors: string[];
  onChange: (event) => void;
  onBlur: () => void;
  align: string;
}

const HoldingRowWeightCell: React.FunctionComponent<HoldingRowWeightCellProps> = ({
  className,
  value,
  disabled,
  errors,
  onChange,
  onBlur,
  align,
}) => {
  const formatWeight = (weight) => {
    if (disabled) {
      return percentage(weight);
    } else {
      return weight;
    }
  };

  return (
    <div className={className}>
      <StyledInput
        type="text"
        value={formatWeight(value)}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        align={align}
      />
      <Errors errors={errors} />
    </div>
  );
};
export default HoldingRowWeightCell;
