import { css } from "styled-components";

import colors from "../../../styles/includes/_colors.scss";
import { Alignment, columnAlignment } from "../../shared/util/alignment-helpers";

export interface CellProps {
  // column is a complicated mess of react-table types - we give up trying to get it right
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: any;
  className?: string;
}

export const CELL_PADDING = 5;

const cellAlignmentStyle = ({ column }: CellProps) => {
  const alignment = columnAlignment(column);
  if (alignment === Alignment.Right) {
    return css`
      text-align: right;
    `;
  }
  return "";
};

export const cellStyles = css`
  font-weight: normal;
  border-bottom: 1px solid ${colors.silver};
  ${cellAlignmentStyle};
`;

export const paddedCellStyle = css`
  ${cellStyles};
  padding-left: ${CELL_PADDING}px;
  padding-right: ${CELL_PADDING}px;
`;
