import React from "react";
import styled, { css } from "styled-components";

import { Format } from "../../shared/util/data-formatters";

import { CELL_PADDING, paddedCellStyle } from "./Cell";
import { DataCellProps } from "./DataCell";
import { rowSpanProps, RowSpanWrapper } from "./RowSpanWrapper";
import { SORTER_INDICATOR_WIDTH } from "./SortableHeaderCell";

function SimpleDataCell(props: DataCellProps): JSX.Element {
  const { className, cell, rowSpan } = props;

  return (
    <RowSpanWrapper rowSpan={rowSpan}>
      <td className={className} {...rowSpanProps(rowSpan)} {...cell.getCellProps()}>
        {cell.render("Cell")}
      </td>
    </RowSpanWrapper>
  );
}

const StyledSimpleDataCell = styled(SimpleDataCell)`
  ${paddedCellStyle};
  padding-right: ${({ column }: DataCellProps) => (column.canSort ? SORTER_INDICATOR_WIDTH : 0) + CELL_PADDING}px;
  ${({ column }: DataCellProps) =>
    column.format !== Format.Text &&
    css`
      white-space: nowrap;
    `};
`;

export { StyledSimpleDataCell as SimpleDataCell };
