import React from "react";
import styled from "styled-components";

import iconInfo from "../../images/icon_info.svg";

import { FlexContainer } from "./shared/shared-components";

const Weight = styled.div`
  width: 100%;
`;

const spanCSS = {
  marginLeft: "5px",
  verticalAlign: "middle",
};

interface WeightIndicatorProps {
  remainingWeight: number;
}

const WeightIndicator: React.FunctionComponent<WeightIndicatorProps> = ({ remainingWeight }) => {
  const message = `${remainingWeight}% remaining`;

  return (
    <FlexContainer>
      <Weight>
        <img src={iconInfo} alt="icon info" />
        <span style={spanCSS}>{message}</span>
      </Weight>
    </FlexContainer>
  );
};

export default WeightIndicator;
