import React, { ReactInstance } from "react";
import ReactDOM from "react-dom";

export const bootstrapUJSComponents = (instance: ReactInstance) => {
  setTimeout(() => {
    // eslint-disable-next-line react/no-find-dom-node
    const mountContext = ReactDOM.findDOMNode(instance);

    if (!mountContext) {
      // The component isn't in the DOM. This can happen with a modal, for instance, that only attaches itself
      // under certain circumstances.
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).ReactRailsUJS.mountComponents(mountContext);
  });
};

/**
 * This method can be used for React components that need to ingest raw haml. It should not be
 * used for any other purpose.
 *
 * @param content
 */
export const renderRawContent = (content: string): JSX.Element => {
  if (!content) {
    return null;
  }

  // Note: dangerouslySetInnerHTML expects unescaped content. See
  // https://stackoverflow.com/questions/1912501/unescape-html-entities-in-javascript/34064434#34064434.
  //
  // If you're rendering in context of a preview rather than from HamlLand, the content will be escaped.
  // But what you're proving in that case is that the component accepts arbitrary content.
  // No need to worry about semantics.
  //
  // If we ever need to unescape HTML ourselves -- danger -- see here
  // https://stackoverflow.com/questions/1912501/unescape-html-entities-in-javascript/34064434#34064434
  // for some notes on how to do so without introducing a security concern.

  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};
