import BaseClient from "../../shared/models/base-client";

import { Portfolio } from "./portfolio";
import { ApiResponse } from "./api-response";
import { Holding } from "./holding";
import HoldingsPaths from "./holdings-paths";

interface InvestmentVehicleResponse {
  ticker: string;
  name: string;
  morningstarId: string;
}

interface PortfolioSaveResponse extends ApiResponse {
  id?: number;
  errors?: {
    name?: string[];
    holdings?: string[];
  };
}

type GetInvestmentVehicles = (query: string, limit: number) => Promise<InvestmentVehicleResponse[]>;

class HoldingsApi extends BaseClient {
  constructor(holdingsPaths: HoldingsPaths) {
    super();
    this.holdingsPaths = holdingsPaths;
    this.getInvestmentVehicles = this.getInvestmentVehicles.bind(this);
  }

  private holdingsPaths: HoldingsPaths;

  public getInvestmentVehicles(query: string, limit: number): Promise<InvestmentVehicleResponse[]> {
    return (
      super
        .get("investment_vehicles", { queryParams: { q: query, limit: limit.toString() } })
        .then((res) => res.json())
        // console.log is just a placeholder. We should probably be doing something nicer with this.
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err))
    );
  }

  public getPortfolios(name: string): Promise<Portfolio[]> {
    return (
      super
        .get(this.holdingsPaths.indexUrl(), { queryParams: { name: name } })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        // console.log is just a placeholder. We should probably be doing something nicer with this.
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err))
    );
  }

  public create(holdings: Holding[], name: string): Promise<PortfolioSaveResponse> {
    const reqBody = { name, holdings };
    return (
      super
        .post(this.holdingsPaths.createUrl(), reqBody)
        .then((response) => response.json())
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err))
    );
  }

  public update({ holdings, name, id }: Portfolio): Promise<PortfolioSaveResponse> {
    const reqBody = { name, holdings };
    return (
      super
        .put(this.holdingsPaths.updateUrl(id), reqBody)
        .then((response) => response.json())
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err))
    );
  }
}

export default HoldingsApi;

export { InvestmentVehicleResponse, GetInvestmentVehicles };
