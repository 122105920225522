import React from "react";
import styled from "styled-components";

import Errors from "../shared/Errors";

import { FlexContainer, GridCell, GridContainer, InvertedButton } from "./shared/shared-components";
import Holdings from "./Holdings";
import HoldingsFormControlsContainer from "./HoldingsFormControlsContainer";
import { Holding } from "./models/holding";
import { Portfolio } from "./models/portfolio";
import { GetInvestmentVehicles } from "./models/data-api";
import { CrudMode } from "./HoldingsCrudContainer";

const StyledGridContainer = styled(GridContainer)`
  margin-bottom: 40px;
  .portfolio-form__grid-cell {
    padding: 0 60px 0 60px;
  }
`;

export interface HoldingsFormProps {
  portfolio: Portfolio;
  addHolding: (holding: Holding) => void;
  updateHoldingWeight: (holding: Holding, newWeight: number) => void;
  removeHolding: (holding: Holding) => void;
  getInvestmentVehicles: GetInvestmentVehicles;
  submit: () => void;
  crudMode: CrudMode;
}

const HoldingsForm: React.FunctionComponent<HoldingsFormProps> = ({
  portfolio,
  addHolding,
  updateHoldingWeight,
  removeHolding,
  getInvestmentVehicles,
  submit,
  crudMode,
}) => {
  const areHoldingsEmpty = portfolio.holdings.length === 0;

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    submit();
  };

  const submitText = crudMode === CrudMode.Crud ? "Save" : "Next";

  return (
    <StyledGridContainer>
      <GridCell start={2} end={12} className="portfolio-form__grid-cell">
        <form onSubmit={onSubmit}>
          <HoldingsFormControlsContainer
            holdings={portfolio.holdings}
            addHolding={addHolding}
            getInvestmentVehicles={getInvestmentVehicles}
            currentTotalWeight={portfolio.totalWeight}
          />
          <Errors errors={portfolio.errors.holdings} />
          <Holdings
            holdings={portfolio.holdings}
            updateHoldingWeight={updateHoldingWeight}
            removeHolding={removeHolding}
            portfolioTotalWeight={portfolio.totalWeight}
            totalWeightError={portfolio.errors.totalWeight}
          />
          <FlexContainer direction="row">
            <InvertedButton type="Submit" hidden={areHoldingsEmpty}>
              {submitText}
            </InvertedButton>
          </FlexContainer>
        </form>
      </GridCell>
    </StyledGridContainer>
  );
};

export default HoldingsForm;
