import React from "react";
import { ResponsivePie } from "@nivo/pie";
import zipWith from "lodash.zipwith";
import styled, { ThemeProvider } from "styled-components";

import { ChartProps, ColorRow, Column, Data } from "./models/chart-props";
import { NivoData } from "./nivo/NivoData";
import { PieChartTheme, Theme, ThemeDefinitions } from "./Theme";

const themeDefinitions = new ThemeDefinitions<PieChartTheme>({
  web: {
    chartContainer: {
      height: "260px",
      width: "260px",
    },
  },
  pdf: {
    chartContainer: {
      height: "150px",
      width: "150px",
    },
  },
  summaryCard: {
    chartContainer: {
      height: "150px",
      width: "150px",
    },
  },
});

const ChartContainer = styled.div`
  margin: 0 auto;
  height: ${(props) => props.theme.chartContainer.height};
  width: ${(props) => props.theme.chartContainer.width};
`;

type PieChartProps = ChartProps<Column, ColorRow, Data, Theme>;

const PieChart = (props: PieChartProps) => {
  const nivoData = NivoData(props);
  const colors = props.rows.map((row) => row.color);
  const names = props.rows.map((row) => row.name);

  const columnName = props.columns[0].name;
  const data = zipWith(names, nivoData.data, (name, data) => {
    return { id: name, label: name, value: data[columnName] as number };
  });

  const tooltipColor = (rowName) => {
    return nivoData.rowsByName[rowName].color;
  };

  const theme = themeDefinitions.pickTheme(props.theme);

  return (
    <ThemeProvider theme={theme}>
      <ChartContainer>
        <ResponsivePie
          data={data}
          innerRadius={0.6}
          padAngle={0}
          cornerRadius={0}
          colors={colors}
          borderWidth={1}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          enableRadialLabels={false}
          enableSlicesLabels={false}
          tooltip={({ value, label }) => nivoData.tooltip(columnName, label, value, tooltipColor(label))}
        />
      </ChartContainer>
    </ThemeProvider>
  );
};

export default PieChart;
