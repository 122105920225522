import React from "react";

import { PortfolioCrudProps } from "./HoldingsCrudContainer";
import HoldingsForm from "./HoldingsForm";
import PortfolioNameInput from "./PortfolioNameInput";

const PortfolioForm: React.FunctionComponent<PortfolioCrudProps> = ({
  portfolio,
  addHolding,
  updateHoldingWeight,
  removeHolding,
  setName,
  savePortfolio,
  getInvestmentVehicles,
  holdingsClass,
  crudMode,
}) => {
  return (
    <>
      <PortfolioNameInput
        name={portfolio.name}
        holdingsClass={holdingsClass}
        errors={[portfolio.errors.name]}
        onChange={setName}
      />
      <HoldingsForm
        portfolio={portfolio}
        addHolding={addHolding}
        updateHoldingWeight={updateHoldingWeight}
        removeHolding={removeHolding}
        getInvestmentVehicles={getInvestmentVehicles}
        submit={savePortfolio}
        crudMode={crudMode}
      />
    </>
  );
};

export default PortfolioForm;
