import React, { useLayoutEffect, useRef, useState } from "react";
import { Row as ReactTableRow, useExpanded, useSortBy, useTable } from "react-table";
import styled from "styled-components";

import { format } from "../shared/util/data-formatters";
import { Alignment } from "../shared/util/alignment-helpers";
import colors from "../../styles/includes/_colors.scss";
import { sortType } from "../shared/util/data-sorters";
import eventBus from "../shared/util/event-bus";

import { ChartProps, Column, DataValue, Row } from "./models/chart-props";
import { HeaderCell } from "./data_table/HeaderCell";
import { DataCell } from "./data_table/DataCell";
import { ScrollPrompt } from "./data_table/ScrollPrompt";
import { TableTheme, Theme, ThemeDefinitions } from "./Theme";
import { getTablesData } from "./nivo/NivoHelpers";

function buildRowSpans(rows: ReactTableRow[], columns: DataTableColumn[]) {
  return columns.map((col) => {
    const rowSpans: number[] = new Array(rows.length).fill(1);
    if (col.group) {
      let parentRowIndex: number;
      let parentValue;
      rows.map((row, i) => {
        const currentValue = row.values[col.index];
        if (parentValue && parentValue === currentValue) {
          rowSpans[i] = 0;
          rowSpans[parentRowIndex]++;
        } else {
          parentRowIndex = i;
          parentValue = currentValue;
        }
      });
    }
    return rowSpans;
  });
}

function Table({ columns: userColumns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: userColumns,
      data,
    },
    useSortBy,
    useExpanded
  );

  // if table has a delete_link column then it has an associated modal, so observe table row changes
  if (userColumns.some((col) => col.format === "delete_link")) {
    // after rows are changed/re-rendered trigger this event so modals can re-establish event listeners
    React.useEffect(() => {
      eventBus.dispatch("renderModalTrigger", userColumns[0].name);
    }, [rows]);
  }

  const getRowClass = (row: ReactTableRow) => {
    if ((row as any).canExpand) {
      return "parent-row";
      // You may have top level rows that don't have children and are not expandable. The ID seems to be the surest way of
      // identifying child rows. In contrast to parent or top level rows, their ids will be something like 0.1, 2.3, etc.
    } else if (row.id.match(/\d+\.\d+/)) {
      return "child-row";
    } else {
      return "";
    }
  };
  const rowSpans = buildRowSpans(rows, userColumns);

  return (
    <div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, ix) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={ix}>
              {headerGroup.headers.map((column, i) => (
                <HeaderCell column={column} key={`row_${i}`} />
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i} className={getRowClass(row)}>
                {row.cells.map((cell, j) => {
                  return <DataCell cell={cell} rowSpan={rowSpans[j][i]} key={`cell_${i}_${j}`} column={cell.column} />;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const themeDefinitions = new ThemeDefinitions<TableTheme>({
  web: { scrollable: false },
  pdf: { scrollable: false },
  scrollable: { scrollable: true },
});

const Styles = styled.div`
  table {
    width: 100%;

    thead {
      th {
        font-weight: normal;
        background: ${colors.white};
        color: #666666;
        text-transform: uppercase;
        font-size: 12px;
      }
    }

    tr {
      height: 40px;
      font-size: 14px;
    }
  }

  @media print {
    table {
      thead {
        th {
          font-size: 8px;
          word-break: break-word;
        }
      }

      tr {
        height: 22px;
        font-size: 8px;
        
        &.parent-row {
          color: #00679b;
        }
        
        td:first-child img {
          width: 14px;
        }

        &.child-row {
          td:nth-child(2) {
            padding-left: 10px;
          }
        }
      }
    }
  }

  ${({ theme, hasColorColumn }) =>
    theme.scrollable &&
    `      
    & > div:first-child {
      overflow-x: scroll;
      white-space: nowrap;
      box-shadow: -35px 0 25px -20px ${colors.alto} inset;
      
      th:first-of-type, td:first-of-type {
        // If color column does not exist, we want to set background color to white so there is no text overlap when
        // scrolling. If it does exist, do nothing because the data will determine the background color.
        ${
          !hasColorColumn &&
          `
            background: ${colors.white};
          `
        }
        background-clip: padding-box;
        left: 0;
        position: sticky;
      }
    }
  `}

  // If scrollable chart has asset class color column, we want to lock down an additional column so that both the 
  // first column (asset class color) and the second column (fund name) stay fixed.
  ${({ theme, hasColorColumn }) =>
    theme.scrollable &&
    hasColorColumn &&
    `
    & > div:first-child {
      th:nth-of-type(2), td:nth-of-type(2) {
        background: ${colors.white};
        background-clip: padding-box;
        left: 10px;
        position: sticky;
      }
    }
  `}
`;

const PageBreak = styled.p`
  page-break-after: always;
`;

const TableContainer = styled.div`
  // Needed to offset footer margin. We also only want it for subsequent tables, but not the first.
  &:not(:first-of-type) {
    margin-top: 30px;
  }
`;

function cellFormatter(header: DataTableColumn): ({ cell: { value } }) => string | JSX.Element {
  return ({ cell: { value } }) => format(header, value);
}

const isSortDisabled = (header: Column, theme: Theme): boolean => header.name === "" || theme === Theme.PDF;

export interface DataTableColumn extends Column {
  group?: boolean;
  align?: Alignment;
}

type DataTableDataValue = DataValue | DataTableDataValue[] | DataTableData;

export interface DataTableData {
  children?: DataTableData[];
  [x: string]: DataTableDataValue;
}

type DataTableProps = ChartProps<DataTableColumn, Row, DataTableData, Theme>;

function DataTable(props: DataTableProps) {
  const columns = React.useMemo(
    () =>
      props.columns.map((column) => {
        return {
          ...column,
          accessor: column.index,
          Header: column.name,
          Cell: cellFormatter(column),
          disableSortBy: isSortDisabled(column, props.theme),
          sortDescFirst: true,
          sortType: sortType(column),
        };
      }),
    []
  );

  const hasColorColumn = columns.filter((c) => c.index === "color").length === 1;

  const data = React.useMemo(
    () =>
      props.data.map((d) => {
        // If color column exists, we need to override any null colors with white to avoid any potential text overlap
        // when scrolling.
        if (hasColorColumn && d.color === null) {
          d.color = "#ffffff";
        }
        // Expand parent rows when the theme is pdf since they aren't clickable.
        return { ...d, expanded: props.theme === "pdf", subRows: d.children };
      }),
    []
  );

  const theme = themeDefinitions.pickTheme(props.theme);

  const ref = useRef(null);
  const [width, setWidth] = useState(0); //
  useLayoutEffect(() => {
    // Divide the width by 2 because when rendering PDF everything is scaled 200%.
    setWidth(ref.current ? ref.current.offsetWidth / 2 : 0);
  }, [ref.current]);

  const tablesData = getTablesData(data, props.theme, props.columns, width);

  return (
    <Styles theme={theme} hasColorColumn={hasColorColumn} ref={ref}>
      {tablesData.map((tableData, i) => {
        return (
          <TableContainer key={i}>
            <Table columns={columns} data={tableData} />
            {i + 1 !== tablesData.length && <PageBreak />}
          </TableContainer>
        );
      })}
      {theme?.scrollable && <ScrollPrompt />}
    </Styles>
  );
}

export { DataTable };
