import React from "react";
import styled from "styled-components";

import alert from "../../images/alert.svg";
import { FlexContainer } from "../portfolio_wizard/shared/shared-components";
import colors from "../../styles/includes/_colors.scss";

const Error = styled.div`
  width: 100%;
  color: ${colors.errorRed};

  img {
    width: 20px;
    height: 20px;
  }
`;

const spanCSS = {
  marginLeft: "5px",
  verticalAlign: "middle",
};

Error.displayName = "Error";

interface ErrorsProps {
  errors: string[];
}

const Errors: React.FunctionComponent<ErrorsProps> = ({ errors }) => {
  return typeof errors === "undefined" || errors.every((e) => e === null || e === undefined) ? (
    <></>
  ) : (
    <FlexContainer>
      {errors.map((error, index) => (
        <Error key={index}>
          <img src={alert} alt="error icon" />
          <span style={spanCSS}>{error}</span>
        </Error>
      ))}
    </FlexContainer>
  );
};

export default Errors;
