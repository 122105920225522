import React from "react";
import styled, { css } from "styled-components";

import neutralSort from "./neutral_sort.svg";
import sortArrow from "./sort_arrow.svg";
import { SortDirection } from "./SortableHeaderCell";

interface SorterProps {
  className: string;
  sortDirection: SortDirection;
}

const SortIndicator = ({ className, sortDirection }: SorterProps): JSX.Element => {
  let sortImg;
  switch (sortDirection) {
    case SortDirection.Neutral:
      sortImg = neutralSort;
      break;
    case SortDirection.Ascending:
    case SortDirection.Descending:
      sortImg = sortArrow;
  }
  return <span className={className}>{sortImg && <img src={sortImg} />}</span>;
};

const StyledSortIndicator = styled(SortIndicator)`
  text-align: center;

  img {
    ${({ sortDirection }) =>
      sortDirection === SortDirection.Ascending &&
      css`
        transform: rotate(180deg);
      `};
  }
`;

export { StyledSortIndicator as SortIndicator };
