const enum Theme {
  WEB = "web",
  PDF = "pdf",
  SCROLLABLE = "scrollable",
}

interface WebTheme {
  web: { [key: string]: any };
}

interface ChartTheme extends WebTheme {
  pdf: { [key: string]: any };
}

interface PieChartTheme extends ChartTheme {
  summaryCard: { [key: string]: any };
}

interface TableTheme extends WebTheme {
  pdf: { [key: string]: any };
  scrollable: { [key: string]: any };
}

class ThemeDefinitions<T extends WebTheme> {
  definitions: T;

  constructor(definitions: T) {
    this.definitions = definitions;
  }

  pickTheme(theme: Theme) {
    return !theme ? this.definitions.web : this.definitions[theme];
  }
}

export { ChartTheme, TableTheme, PieChartTheme, ThemeDefinitions, Theme };
