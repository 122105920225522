import React from "react";
import "react-tippy/dist/tippy.css";
import { Size, Tooltip as Tippy, TooltipProps as TippyTooltipProps, Theme as TippyTheme } from "react-tippy";

import { bootstrapUJSComponents } from "../util/raw-content-helpers";
import "./AnchoredOverlay.scss";

export enum Position {
  TOP = "top",
  TOP_END = "top-end",
  TOP_START = "top-start",
  BOTTOM = "bottom",
  BOTTOM_END = "bottom-end",
  BOTTOM_START = "bottom-start",
  LEFT = "left",
  LEFT_END = "left-end",
  LEFT_START = "left-start",
  RIGHT = "right",
  RIGHT_END = "right-end",
  RIGHT_START = "right-start",
}

export enum Theme {
  ULTRA_DARK = "ultra-dark",
}

export interface CommonOverlayProps {
  triggerElement: JSX.Element;
  content: JSX.Element;

  arrow?: boolean;
  arrowSize?: Size;
  distance?: number;
  theme?: Theme;
  offset?: number;
  position?: Position;
}

export enum Trigger {
  MANUAL = "manual",
}

interface ControlledProps {
  onRequestClose: () => void;
  open: boolean;
  trigger: Trigger;
}

export interface AnchoredOverlayProps extends CommonOverlayProps {
  interactive?: boolean;
  controlled?: ControlledProps;
}

interface AnchoredOverlayState {
  bootstrapped: boolean;
}

/**
 * This component is intended for use within the shared package. Don't make use of it directly. Use Tooltip or
 * Popover instead.
 *
 * Our AnchoredOverlay uses tippy.js, which is powered by popper.js, under the hood. For ergonomic reasons, we expose only
 * a subset of the API's that they provide. Generally, if you can pass a property into vanilla tippy or popper,
 * we can add support for that property here if there's ever a need for it.
 *
 * See https://atomiks.github.io/tippyjs/themes/ for information on how to custom style this element.
 *
 * @constructor
 */
// The _ is a little weird. But we want it in this case as a "do not use" marker for folks importing from the package.
// If the design system were a node module, we'd solve the same problem by not re-exporting AnchoredOverlay from the
// index.
// tslint:disable-next-line:class-name
export default class AnchoredOverlay extends React.Component<AnchoredOverlayProps, AnchoredOverlayState> {
  constructor(props) {
    super(props);
    this.state = {
      bootstrapped: false,
    };
  }

  public render() {
    const {
      triggerElement,
      content,
      interactive = false,
      arrow = false,
      arrowSize = "regular",
      distance = 10,
      offset = 0,
      position = Position.TOP,
      theme = Theme.ULTRA_DARK,
      controlled = {},
    } = { ...this.props };

    const themeWithPosition = `anchored-overlay-${theme}-${position.match(/top|bottom/) ? "vertical" : "horizontal"}`;

    let tippyProps: TippyTooltipProps = {
      position,
      arrow,
      arrowSize,
      distance,
      interactive,
      offset,
      animation: "fade",
      duration: 250,
      theme: themeWithPosition as TippyTheme,
      html: content,
    };

    tippyProps = {
      ...tippyProps,
      ...controlled,
    };

    // See https://github.com/tvkhoa/react-tippy/issues/10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (tippyProps as any).key = `${arrow}-${offset}-${position}`;

    return <Tippy {...tippyProps}>{triggerElement}</Tippy>;
  }

  public componentDidUpdate(): void {
    if (!this.state.bootstrapped) {
      bootstrapUJSComponents(this);
      this.setState({ bootstrapped: true });
    }
  }
}
