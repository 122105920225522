import Turbolinks from "turbolinks";

class Utilities {
  public setLocation(path) {
    Turbolinks.visit(path);
  }
}

const RouteUtilities = new Utilities();

export default RouteUtilities;
