import React from "react";
import styled from "styled-components";

const HeaderCell = styled.div`
   {
    font-size: 12px;
    vertical-align: middle;
    line-height: normal;
    transition: all 0.3s ease;
    border-bottom: solid 1px #cccccc;
    margin-bottom: 3px;
    padding: 10px;

    svg {
      margin-top: 5px;
      margin-left: 15px;
      float: right;
      visibility: hidden;
    }

    &:hover {
      border-bottom: solid 3px black;
      transform: rotate(0deg) translate(0px, -3px);
      margin-bottom: 0px;

      svg {
        visibility: visible;
      }
    }

    &:last-child {
      text-align: right;
    }
  }
`;

const GridContainer = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(3, 1fr);

  ${HeaderCell}:nth-child(1) {
    -ms-grid-column: 1;
  }

  ${HeaderCell}:nth-child(2) {
    -ms-grid-column: 2;
  }

  ${HeaderCell}:nth-child(3) {
    -ms-grid-column: 3;
  }
`;

const HoldingsHeaders = () => (
  <GridContainer>
    <HeaderCell>SYMBOL</HeaderCell>
    <HeaderCell>SECURITY NAME</HeaderCell>
    <HeaderCell>WEIGHT</HeaderCell>
  </GridContainer>
);

export default HoldingsHeaders;
