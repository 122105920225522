import React from "react";
import AsyncSelect from "react-select/async";

import Errors from "../shared/Errors";

import { GetInvestmentVehicles } from "./models/data-api";
import { PrimaryButton, FlexContainer, GridContainer, GridCell } from "./shared/shared-components";
import WeightIndicator from "./WeightIndicator";
import { Holding } from "./models/holding";

const inputFieldCSS = {
  backgroundColor: "#fff",
  padding: "0px 0px 0px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  border: "solid 1px #b8b8b8",
  height: "40px",
  width: "100%",
  outlineWidth: "0",
};

const reactSelectContainerCss = {
  width: "100%",
};

// this is Select specific format
// https://react-select.com/styles#provided-styles-and-state
const reactSelectStyleOverrides = {
  control: () => inputFieldCSS,
  container: () => reactSelectContainerCss,
};

const paddedContainerCSS = {
  marginTop: "5px",
};

// hide Select's dropdown icon
const components = {
  DropdownIndicator: null,
};

interface HoldingsFormControlsProps {
  holdings: Holding[];
  addHolding?: (holding: Holding) => void;
  getInvestmentVehicles: GetInvestmentVehicles;
  currentTotalWeight?: number;
}

const HoldingsFormControlsContainer: React.FunctionComponent<HoldingsFormControlsProps> = ({
  holdings,
  addHolding,
  getInvestmentVehicles,
  currentTotalWeight,
}) => {
  const [newHolding, setNewHolding] = React.useState(new Holding({ validate: true }));
  const [showErrors, setShowErrors] = React.useState(false);

  const handleWeightChange = (event) => {
    // To keep original synthetic event when setting state.
    event.persist();

    setNewHolding((prevHolding) => {
      const holding = prevHolding.cloneDeep();
      holding.weight = event.target.value;
      return holding;
    });

    setShowErrors(true);
  };

  const handleValueChange = (selectedOption) => {
    setNewHolding((prevHolding) => {
      const holding = prevHolding.cloneDeep();
      holding.name = selectedOption.name;
      holding.ticker = selectedOption.ticker;
      holding.morningstarId = selectedOption.morningstarId;
      return holding;
    });
  };

  const loadOptions = (inputValue, callback) => {
    // Increasing the limit to give us adequate buffer to prevent scenario where user has added all the holdings that
    // are returned from a given query. If this doesn't work, we should look into adding pagination for the select.
    // See: https://github.com/vtaits/react-select-async-paginate/tree/master/packages/react-select-async-paginate
    getInvestmentVehicles(inputValue, 50).then((data) => {
      const ids = holdings.map((h) => h.morningstarId);
      const filteredData = data.filter((d) => !ids.includes(d.morningstarId));
      return callback(filteredData);
    });
  };

  const createHolding = () => {
    if (newHolding.isValid()) {
      addHolding(newHolding);
      setNewHolding(new Holding({ validate: true }));
      setShowErrors(false);
    } else {
      setShowErrors(true);
    }
  };

  const getOptionLabel = (holding) => `${holding.ticker} ${holding.name}`;
  // Select needs a pointer to a unique 'value' for the option so it can detect dups
  // and mark 'selected' options
  const getOptionValue = (holding) => holding.morningstarId;

  const noOptionsMessage = (props) => {
    const { inputValue } = props;
    return inputValue.length > 0
      ? "No results found. Please check your input or try another search term."
      : "Start typing to search by symbol or security name";
  };

  // Returns <Errors> with holding weight errors or <WeightIndicator> displaying remaining available weight in portfolio.
  const weightIndicator = () => {
    if (showErrors) {
      // Total weight is validated at portfolio level so we don't have context of error at holding level. We need to
      // manually determine if portfolio becomes overweight with new holding and then pass the error message, if any,
      // to the Errors component to render.
      const remainingWeight: number = 100 - Number(newHolding.weight) - currentTotalWeight;
      const totalWeightError: string =
        remainingWeight < 0 ? `Portfolio overweight by ${Math.abs(remainingWeight)}%` : null;

      return newHolding.errors.weight || totalWeightError ? (
        <Errors errors={[newHolding.errors.weight || totalWeightError]} />
      ) : (
        <WeightIndicator remainingWeight={remainingWeight} />
      );
    }
  };

  return (
    <>
      <GridContainer columns={12} gap="20px">
        {/* ticker input field */}
        <GridCell start={1} end={8}>
          {/* position: relative is needed for correct AsyncSelect default positioning behavior. */}
          <FlexContainer align="left" style={{ position: "relative" }}>
            <label>Type a symbol or security name</label>
            <AsyncSelect
              components={components}
              styles={reactSelectStyleOverrides}
              loadOptions={loadOptions}
              placeholder={"Example: SPY or SPDR S&P 500 ETF Trust"}
              onChange={handleValueChange}
              noOptionsMessage={noOptionsMessage}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              value={
                newHolding.morningstarId
                  ? { ticker: newHolding.ticker, name: newHolding.name, morningstarId: newHolding.morningstarId }
                  : null
              }
            />
            {showErrors && newHolding.errors.ticker ? (
              <FlexContainer align="left" style={paddedContainerCSS}>
                <Errors errors={[newHolding.errors.ticker]} />{" "}
              </FlexContainer>
            ) : (
              <></>
            )}
          </FlexContainer>
        </GridCell>
        {/* weight input field */}
        <GridCell start={8} end={13}>
          <FlexContainer align="left">
            <label>Enter a percentage</label>
            <input
              style={inputFieldCSS}
              placeholder="Example: enter 14.5 for 14.5%"
              value={newHolding.weight !== null ? newHolding.weight : ""}
              onChange={handleWeightChange}
            />
            <FlexContainer align="left" style={paddedContainerCSS}>
              {weightIndicator()}
            </FlexContainer>
          </FlexContainer>
        </GridCell>
      </GridContainer>
      {/* add holding row button */}
      <FlexContainer>
        <PrimaryButton margin="40px 10px 10px 10px" onClick={createHolding} type={"button"}>
          Add investment
        </PrimaryButton>
      </FlexContainer>
    </>
  );
};

export default HoldingsFormControlsContainer;
