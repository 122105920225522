import React, { ChangeEvent } from "react";

import Errors from "../shared/Errors";

import { FlexContainer, GridCell, GridContainer } from "./shared/shared-components";

export interface PortfolioNameProps {
  name: string;
  holdingsClass: string;
  errors: string[];
  onChange: (name: string, validate: boolean) => void;
}

const PortfolioNameInput: React.FunctionComponent<PortfolioNameProps> = ({ name, holdingsClass, errors, onChange }) => {
  const updatePortfolioName = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value, true);
  };

  const inputFieldCSS = {
    backgroundColor: "#fff",
    padding: "0px 0px 0px 10px",
    borderRadius: "10px",
    fontSize: "16px",
    border: "solid 1px #b8b8b8",
    height: "40px",
    width: "660px",
    outlineWidth: "0",
  };

  const inputBoxCss = {
    margin: "40px 0 0 60px",
  };

  const holdingsClassName = holdingsClass[0].toUpperCase() + holdingsClass.substr(1);

  return (
    <>
      <GridContainer>
        <GridCell start={2} end={12} className="portfolio-form__grid-cell">
          <FlexContainer align="left" style={inputBoxCss}>
            <label> {holdingsClassName} Name </label>
            <input
              style={inputFieldCSS}
              type="text"
              value={name || ""}
              placeholder={`Example: "Moderate ${holdingsClassName}"`}
              maxLength={150}
              onChange={updatePortfolioName}
            />
            <Errors errors={errors} />
          </FlexContainer>
        </GridCell>
      </GridContainer>
    </>
  );
};

export default PortfolioNameInput;
