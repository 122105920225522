import React from "react";
import styled from "styled-components";

import colors from "../../../styles/includes/_colors.scss";
import rightArrow from "../../../images/arrow_right.svg";

interface ScrollLegendProps {
  className: string;
}

const ScrollPrompt: React.FunctionComponent<ScrollLegendProps> = ({ className }) => {
  return (
    <div className={className}>
      <p>
        Scroll for More
        <img src={rightArrow} />
      </p>
    </div>
  );
};

const StyledScrollPrompt = styled(ScrollPrompt)`
  margin-bottom: 20px;
  position: relative;

  p {
    color: ${colors.doveGray};
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 20px;
  }

  img {
    margin-left: 10px;
    width: 15px;
  }
`;

export { StyledScrollPrompt as ScrollPrompt };
