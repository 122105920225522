import React from "react";

import { CommonOverlayProps } from "../overlays/AnchoredOverlay";
import { renderRawContent } from "../util/raw-content-helpers";

const withAnchoredOverlayAdapter = (WrappedComponent: React.ComponentType<CommonOverlayProps>) => {
  return (props) => {
    const mergedProps = {
      ...props,
      triggerElement: renderRawContent((props.triggerElement as unknown) as string),
      content: renderRawContent((props.content as unknown) as string),
    };

    return <WrappedComponent {...mergedProps} />;
  };
};

export default withAnchoredOverlayAdapter;
