import React from "react";

import { CellProps } from "./Cell";
import { SortableHeaderCell } from "./SortableHeaderCell";
import { SimpleHeaderCell } from "./SimpleHeaderCell";

const HeaderCell = ({ column }: CellProps): JSX.Element => {
  const HeaderCellClass = column.canSort ? SortableHeaderCell : SimpleHeaderCell;
  return <HeaderCellClass column={column} />;
};

export { HeaderCell };
