import Hypher from "hypher";
import english from "hyphenation.en-us";

const hyphenator = new Hypher(english);

export const hyphenate = (word: string, maxSegmentLength: number): string | string[] => {
  if (word.length <= maxSegmentLength) {
    return word;
  }

  // Chunks => Parts of the word after which you could insert a hyphen. Each may be well under the maxSegmentLength
  // so further processing is needed.
  let [segment, ...chunks] = hyphenator.hyphenate(word);
  const segments: string[] = [];

  for (let i = 0; i < chunks.length; i++) {
    const chunk: string = chunks[i];
    const candidateSegment = `${segment}${chunk}`;

    const candidateExceedsMaxLength = candidateSegment.length > maxSegmentLength;

    if (candidateExceedsMaxLength && !chunks[i + 1]) {
      segments.push(`${segment}-`);
      segments.push(chunk);
    } else if (candidateExceedsMaxLength) {
      segments.push(`${segment}-`);
      segment = chunk;
    } else {
      segment = candidateSegment;
    }
  }

  return segments;
};
