import { CrudMode, HoldingsClass } from "../HoldingsCrudContainer";

class HoldingsPaths {
  constructor(holdingsClass: HoldingsClass, crudMode: CrudMode, basePath: string) {
    this.holdingsClass = holdingsClass;
    this.crudMode = crudMode;
    this.basePath = basePath;
  }

  public holdingsClass: HoldingsClass;
  private crudMode: CrudMode;
  private basePath: string;

  indexUrl() {
    switch (this.holdingsClass) {
      case HoldingsClass.Portfolio:
        return "portfolios";
      case HoldingsClass.Benchmark:
        return "benchmarks";
    }
  }

  createUrl() {
    switch (this.holdingsClass) {
      case HoldingsClass.Portfolio:
        return "analysis/portfolios";
      case HoldingsClass.Benchmark:
        return "benchmarks";
    }
  }

  updateUrl(id: string) {
    switch (this.holdingsClass) {
      case HoldingsClass.Portfolio:
        return `portfolios/${id}`;
      case HoldingsClass.Benchmark:
        return `benchmarks/${id}`;
    }
  }

  successRedirectUrl(id: number) {
    if (this.crudMode === CrudMode.AnalysisWizard) {
      if (this.holdingsClass === HoldingsClass.Portfolio) {
        return `${this.basePath}/${id}/benchmarks`;
      } else {
        return `${this.basePath}/${id}`;
      }
    }

    return `${this.basePath}`;
  }
}

export default HoldingsPaths;
