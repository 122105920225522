import cloneDeep from "lodash.clonedeep";
import { object, string, number } from "yup";

import { validate } from "../../shared/util/yup-helpers";

type HoldingError = {
  ticker?: string;
  weight?: string;
};

class Holding implements Validatable {
  private _name: string;
  private _ticker: string;
  private _weight: number;
  private _morningstarId: string;
  public errors: HoldingError;

  constructor({ ticker = null, name = null, weight = null, morningstarId = null, validate = false }) {
    this._ticker = ticker;
    this._name = name;
    this._weight = weight;
    this._morningstarId = morningstarId;
    this.errors = {};

    if (validate) {
      this.validate();
    }
  }

  static SCHEMA = object({
    ticker: string().required("Please select a security").ensure(),
    name: string().required("Please select a security").ensure(),
    morningstarId: string().required("Please select a security").ensure(),
    weight: number()
      .transform(function (value, originalValue) {
        // check to see if the default transform already parsed value. if so, return
        if (this.isType(value)) {
          return value;
        }
        // check if unparsed value is empty string/undefined/null. if so, we want to return undefined to trigger the
        // required validation
        else if (originalValue === "" || originalValue === undefined || originalValue === null) {
          return undefined;
        }
        // otherwise, value is NaN so return original value to trigger type error
        else {
          return originalValue;
        }
      })
      .test(
        "hasTwoDecimals",
        "Percentage cannot have more than two decimal places",
        (val) => val?.toString().match(/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/) !== null
      )
      .notOneOf(
        [0],
        "Portfolio weight error. Please correct this by entering a weight greater than 0 and less than 100"
      )
      .min(0, "Percentage cannot be negative")
      .max(100, "Percentage cannot be more than 100%")
      .typeError("Please enter numbers only")
      .required("Please enter a weight for this security"),
  });

  get name() {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
    this.validate();
  }

  get ticker() {
    return this._ticker;
  }

  set ticker(ticker: string) {
    this._ticker = ticker;
    this.validate();
  }

  get weight() {
    return this._weight;
  }

  set weight(weight: number) {
    this._weight = weight;
    this.validate();
  }

  get morningstarId() {
    return this._morningstarId;
  }

  set morningstarId(morningstarId: string) {
    this._morningstarId = morningstarId;
    this.validate();
  }

  cloneDeep() {
    return cloneDeep(this);
  }

  isValid() {
    return Holding.SCHEMA.isValidSync(this);
  }

  validate() {
    validate(this, Holding.SCHEMA);
  }

  toJSON() {
    return {
      name: this.name,
      ticker: this.ticker,
      weight: this.weight,
      // eslint-disable-next-line @typescript-eslint/camelcase
      morningstar_id: this.morningstarId,
    };
  }
}

export { HoldingError, Holding };
