import React from "react";
import { Colors } from "@nivo/core";
import { HeatMap, HeatMapDatum } from "@nivo/heatmap";

import { DefaultChartProps } from "./models/chart-props";
import { NivoData } from "./nivo/NivoData";
import { ChartTheme, Theme, ThemeDefinitions } from "./Theme";
import { renderTick, renderWords, splitLabel, tickWordElement } from "./nivo/NivoHelpers";

// This will be set when rendering the bar chart.
let maxLabelWidth;

interface RectProps {
  size: number;
  offset: number;
}

const themeDefinitions = new ThemeDefinitions<ChartTheme>({
  web: {
    maxChartSize: 850,
  },
  pdf: {
    maxChartSize: 480,
  },
});

const DEFAULT_SQUARE_SIZE = 35;
const MARGIN_SIZE = 252;

const calcRectProps = (value: number, width: number): RectProps => {
  // set sizeFactor to 1 to avoid scaling box size based on its numerical value
  const sizeFactor = 1;

  return { size: width * sizeFactor, offset: (-width * sizeFactor) / 2 };
};

const tickLabelElement = (label: string): JSX.Element | JSX.Element[] => {
  // Initial offset can be a little negative because cells have some white space between them.
  const initialOffset = -3;
  const verticalOffset = 15;
  const shortLabelOffset = 5;

  if (label.length <= maxLabelWidth) {
    return tickWordElement(label, shortLabelOffset);
  }

  const words = splitLabel(label, maxLabelWidth);

  return renderWords(words, initialOffset, verticalOffset);
};

/**
 * Calculate the color for each value (aka: Correlation Box) based on a custom scale.
 * NOTE: By default, Nivo takes the difference between the max & min values,
 *  then divides it by the number of colors to make an even scale.
 *
 * Custom Scale:
 *  96 to 100 : #a60000
 *  86 to 95 : #E58080
 *  61 to 85 : #006799
 *  21 to 60 : #80B3CC
 *  -19 to 20 : #86B79F
 *  -100 to -20 : #0D6F3F
 *
 * @param value Correlation number of the square
 */
const calcBoxAndFontColor = (value: number) => {
  if (value >= 96) {
    return { boxColor: "#a60000", fontColor: "#FFFFFF" };
  } else if (value <= 95 && value >= 86) {
    return { boxColor: "#E58080", fontColor: "#000000" };
  } else if (value <= 85 && value >= 61) {
    return { boxColor: "#006799", fontColor: "#FFFFFF" };
  } else if (value <= 60 && value >= 21) {
    return { boxColor: "#80B3CC", fontColor: "#000000" };
  } else if (value <= 20 && value >= -19) {
    return { boxColor: "#86B79F", fontColor: "#000000" };
  } else if (value <= -20) {
    return { boxColor: "#0D6F3F", fontColor: "#FFFFFF" };
  }
};

const CustomCell = ({
  data,
  value,
  x,
  y,
  width,
  color,
  opacity,
  borderWidth,
  borderColor,
  enableLabel,
  onHover,
  onLeave,
  onClick,
  theme,
}) => {
  const { size, offset } = calcRectProps(value, width);
  const colors = calcBoxAndFontColor(value);
  color = colors.boxColor;
  const fontFill = colors.fontColor;

  return (
    <g
      transform={`translate(${x}, ${y})`}
      style={{ cursor: "pointer" }}
      onMouseEnter={onHover}
      onMouseMove={onHover}
      onMouseLeave={onLeave}
      onClick={onClick ? (event) => onClick(data, event) : undefined}
    >
      <rect
        x={offset}
        y={offset}
        width={size}
        height={size}
        fill={color}
        fillOpacity={opacity}
        strokeWidth={borderWidth}
        stroke={borderColor}
        strokeOpacity={opacity}
      />
      {enableLabel && (
        <text
          dominantBaseline="central"
          textAnchor="middle"
          style={{
            ...theme.labels.text,
            fill: fontFill,
          }}
          fillOpacity={opacity}
        >
          {value}
        </text>
      )}
    </g>
  );
};

interface CorrelationMatrixProps extends DefaultChartProps {
  colorScheme: Colors;
  theme: Theme;
}

function CorrelationMatrix(props: CorrelationMatrixProps) {
  const nivoData = NivoData(props);
  const names = props.columns.map((column) => column.name);
  const themeDef = themeDefinitions.pickTheme(props.theme);

  const size = Math.min(props.data.length * DEFAULT_SQUARE_SIZE + MARGIN_SIZE, themeDef.maxChartSize);
  const theme = {
    axis: {
      ticks: {
        text: {
          fontSize: "12px",
          fontFamily: "Fidelity Sans",
          color: "#666666",
          fill: "#666666",
        },
      },
    },
    labels: {
      text: {
        fontSize: themeDef.maxChartSize / 70,
        fontFamily: "Fidelity Sans",
      },
    },
  };

  maxLabelWidth = Math.max(...names.map((words) => words.length));
  // One character is roughly 6px.
  const margin = maxLabelWidth * 9;

  return (
    <HeatMap
      cellShape={CustomCell}
      colors={props.colorScheme}
      data={nivoData.data as HeatMapDatum[]}
      keys={names}
      indexBy={"name"}
      minValue={-100}
      maxValue={100}
      height={size}
      width={size}
      margin={{
        top: margin,
        right: 0,
        bottom: 10,
        left: margin,
      }}
      padding={3}
      enableLabels={true}
      axisTop={{
        tickRotation: -45,
        tickSize: 0,
        renderTick: renderTick(props.theme, tickLabelElement),
      }}
      axisLeft={{
        tickSize: 0,
        renderTick: renderTick(props.theme, tickLabelElement),
      }}
      theme={theme}
    />
  );
}

export default CorrelationMatrix;
